.statistic-page.title {
    height: auto;
    width: auto;
    font-size: x-large;
    font-weight: bold;
    color: white;
    font-family: var(--fontFamily);
    background-color: transparent;
}

.statistic-page.sub-title {
    height: auto;
    width: 450px;
    font-size: large;
    font-weight: bold;
    color: white;
    font-family: var(--fontFamily);
    background-color: transparent;
}

/* Using a class */
.table-margin {
    margin-bottom: 50px;
}

/* Using a class */
.column-margin {
    margin-right: 50px;
}

.statistic-page.table-body {
    color: white;
    padding: '8px';
}

.statistic-page.table-header {
    background: #555;
    color: white;
    padding: '8px';
}

.statistic-page.text {
    margin-top: 10px;
    color: white;
}
