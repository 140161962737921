.css-z8fhq1-MuiDataGrid-columnHeaders{
  background-color:darkgreen;
  color:white;
}

.dispatch-table.outer-container {
  border-radius: var(--borderRadius);
  border: none;
  width: 90vw;

  height: 100%;
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
  display: flex;
  flex-direction: column;

 
}

.dispatch-table.title-row {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--controlVMargin);
}

.dispatch-table.datepicker-button {
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--colorGreen);
  border: none;
}

.dispatch-table.table-container {
  border-radius: var(--borderRadius);
  width: auto;
  overflow-y: auto;
  height: 47.5rem;
  background-color: var(--bgColorNormal);
}

.dispatch-table.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.dispatch-table.text {
  position: relative;
  top: 20px;
  width: auto;
  height: auto;
  font-size: large;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}