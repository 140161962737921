.task.logo-img {
    position: relative;
    width: 30%;
    top: 0px;
    left: 0px;
    margin: 30px;
    background-color: transparent;
  }

.task.Title {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  color: white;
  justify-content: center;
}

.task.map {
  position: relative;
  z-index: 1;
}

.task.driver-info{
  position:relative;
  z-index: 99;
  margin-top:-200px;
  margin-right: 10px;
  margin-left: 10px;
}

.task.route-point{
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.task.route-point img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.task.route-bone{
  display: flex;
  justify-content: left;
  height: 50px;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 5px;
}

.task.card-body{
  margin-left: 30px;
}

.task.route-time{
  display: flex;
  justify-content: right;
}

.task.route-note{
  color:white;
}

.task.driver-img{
  margin-left: -30px
}

.task.driver-img img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}