.button-group {
    display: flex;
    margin-left: var(--controlHMargin);

  }
  
  .button {
    padding: 10px 20px;
    width: 7.5vw;
    height: 45px;
    border: 1px solid #ccc;
    cursor: pointer;
    background: #fff;
    color: #333;
    text-align: center;
    flex: 1;
  }
  
  .button input[type="radio"] {
    display: none;
  }
  
  .button.active {
    background: #3C4F57;
    color: #fff;
    border-color: #3C4F57;
  }
  
  .button:not(:last-child) {
    border-right: none;
  }
  