.extra-info-container {
  position: relative;
  top: -20px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: var(--bgColorDark);
}

.extra-info-card {
  position: relative;
  top: 40px;
  left: 0px;
  margin-top: 20px;
  margin-left: 40px;
  height: 700px;
  width: 1600px;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
}

.extra-info-card-title {
  position: relative;
  text-align: center;
  color: white;
  margin-top: 10px;
  font-weight: bold;
  font-size: x-large;
  font-family: var(--fontFamily);
}

.extra-info-card-chart {
  position: relative;
  top: 5px;
  left: 0px;
  align-items: center;
}
