
.fleet-unregister-device.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-device.info {
  position: relative;
  top: 40px;
  left: 30px;
  height: auto;
  width: auto;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-device.name {
  height: auto;
  width: 500px;
  margin-bottom: var(--controlVMargin);
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-device.name-field {
  margin-bottom: var(--controlVMargin);
  height: auto;
  max-width: 395px;
  font-size: x-large;
  font-weight: bold;
  font-family: var(--fontFamily);
  border-radius: var(--inputBorderRadius);
}

.fleet-unregister-device.image {
  max-width: 309px;
  max-height: auto;
}

.fleet-unregister-device.drag-zone {
  max-width: 309px;
  max-height: auto;
}

.fleet-unregister-device.upload-area {
  width: 309px;
  height: 100px;
  display: flex;
  flex-direction: column;
  color: white;
}

.fleet-unregister-device.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: gray;
  border-style: dashed;
  background-color: transparent;
  color: white;
  outline: none;
  transition: border 0.24s ease-in-out;
  border-radius: 7px;
}

.fleet-unregister-device.image-column {
  width: auto;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
