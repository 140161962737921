/* TunePage.css or App.css */
.tune.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px; /* Adds spacing between cells */
  }
  
  th, td {
    margin: 20px; /* This will not have an effect because margin does not apply directly to table cells in standard CSS */
    padding: 10px; /* Adds padding inside cells */
    background-color: #1a1a1a; /* Example background color for better visibility of white text */
    color: white; /* Ensures text is white */
  }
  
  .tune.button {
    margin: 20px; /* Adds margin around buttons */
  }
  
  .tune.input[type="number"] {
    margin: 20px; /* Adds margin around input fields */
    width: calc(100% - 40px); /* Adjust width to account for margin */
  }
  
  .settings-introduction {
    color: white;
  }