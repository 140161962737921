/* LoginPage.css */

.login.signin-container {
  width: 100%;
}

.login.column {
  display: flex;
  width: 100vw;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login.logo-image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  height: auto;
}

/* Captcha Styles */
.captcha-group {
  display: flex; /* 使用 flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
}

.captcha-container {
  display: flex;
  align-items: center;
  gap: 10px; /* 验证码图片和输入框之间的间距 */
}

.captcha-container .captcha-image {
  /* 验证码图片样式 */
}

.captcha-container .captcha-input {
  /* 验证码输入框样式 */
}

.captcha-container a { 
  color: white; /* 设置 Reload Captcha 链接颜色 */
  font-size: 16px; /* 设置 Reload Captcha 链接字体大小 */
  margin-left: 1rem;
}

.login.logo-image-version {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  color: #999999;
  height: auto;
  width: auto;
}

.login.form-container {
  margin-left: auto;
  margin-right: auto;
  height: 310px;
  width: 90%; /* Adjust width based on screen size */
  max-width: 460px; /* Limit maximum width */
  background-color: transparent;
  padding: 20px; /* Add padding for better spacing */
}

.login.account-bar,
.login.password-bar {
  color: var(--textColor);
  padding-left: 15px;
  width: 100%;
  height: 60px;
  text-align: left;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--inputBackgroundColor);
  border-radius: var(--inputBorderRadius);
  border: none;
}

.login.account-bar:focus,
.login.password-bar:focus {
  color: var(--textColor);
  background-color: transparent;
  border-color: transparent;
}

#input-placeholder::placeholder {
  color: gray;
  opacity: 0.8;
  font-weight: lighter;
}

.login.image {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* This will vertically center the image relative to the input */
  width: 50px;
  height: 50px;
  z-index: 2; /* Ensures the image is above the input */
}

.login.login-button {
  top: 60px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  color: var(--textColor);
  font-size: large;
  font-family: var(--fontFamily);
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 25px;
  background-color: var(--colorGreen);
}

.login.login-button:hover {
  background-color: darkgreen;
  border: none;
  border-color: transparent;
}

.login.error-text {
  position: absolute;
  text-align: center;
  color: red;
  top: 185px;
  height: 30px;
  width: 90%; /* Adjust width based on screen size */
  max-width: 430px;  /* Limit maximum width */
  height: auto;
  font-size: large;
  font-family: var(--fontFamily);
}

.forgot-password-link {
  text-align: center; /* Center-align the link */
  margin-top: 10px;   /* Add some margin for spacing */
}

.forgot-password-link span {
  color: lightblue;        /* Blue text color */
  cursor: pointer;    /* Display a pointer cursor when hovered */
  text-decoration: underline; /* Underline the text on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .login.form-container {
    width: 95%; /* Adjust width for smaller screens */
  }

  .login.error-text {
    width: 95%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .login.form-container {
    width: 98%; /* Further adjust width for very small screens */
  }

  .login.error-text {
    width: 98%; /* Further adjust width for very small screens */
  }
}