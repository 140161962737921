@import './ThemeVariable.css';

.textfield {
  color: var(--textColor);
  padding-left: 15px;
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--inputBackgroundColor);
  border-radius: var(--inputBorderRadius);
  border: none;
  z-index: 1;
}

.textfield-large {
  color: var(--textColor);
  padding-left: 15px;
  width: 100%;
  height: 60px;
  text-align: left;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--inputBackgroundColor);
  border-radius: var(--inputBorderRadius);
  border: none;
  z-index: 1;
}
