.logout-modal {
  background-color: transparent;
}

.logout-modal-header {
  padding-top: 15px;
  padding-bottom: 10px;
  font-family: var(--fontFamily);
  border-top: 2px solid #7777777e;
  border-left: 2px solid #7777777e;
  border-right: 2px solid #7777777e;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--bgColorDark);
}

.logout-modal-title {
  font-family: var(--fontFamily);
  text-align: center;
  color: white;
  font-size: x-large;
  font-weight: 600;
}

.logout-modal-body {
  font-family: var(--fontFamily);
  text-align: center;
  border-left: 2px solid #7777777e;
  border-right: 2px solid #7777777e;
  padding-top: 10px;
  padding-bottom: 20px;
  color: white;
  font-size: large;
  background-color: var(--bgColorDark);
}

.logout-modal-footer {
  padding-top: 10px;
  padding-bottom: 20px;
  border-left: 2px solid #7777777e;
  border-right: 2px solid #7777777e;
  border-bottom: 2px solid #7777777e;
  background-color: var(--bgColorDark);
}

.logout-modal-button {
  position: relative;
  display: block;
  font-family: var(--fontFamily);
  border: none;
  margin: auto;
}

.logout-modal-button:hover {
  border: none;
  background-color: gray;
}
