@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.documentPageContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%; /* Adjust the width as per your layout */
    padding: 20px;
  }

  .login.potal-text {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    color: white;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    height: auto;
  }
  
  .login.logo-image {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: auto;
  }

  .welcomeMessage {
    margin-top: 10px;
    font-size: 20px;
    color: white; /* Adjust the color as needed */
  }

  .centerContent {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically if there's extra space */
    margin: 0 auto;
  }
  .centeredImage {
    margin: 10px; /* Adjust the margin as needed */
    max-width: 100%; /* Ensures the image is responsive */
    height: auto; /* Maintains the aspect ratio of the image */
  }
  .centeredImageStore {
    margin: 10px; /* Adjust the margin as needed */
    width: 300px;
    height: auto; /* Maintains the aspect ratio of the image */
  }
  .tableContainer {
    background-color: #f4f4f4;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer th,
  .tableContainer td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .tableContainer th {
    background-color: #007bff;
    color: white;
  }
  
  .tableContainer tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .tableContainer tr:hover {
    background-color: #ddd;
  }
  
  .whiteText {
    color: white;
  }
  
  .downloadLink {
    color: #007bff;
    text-decoration: none;
  }
  .downloadLink:hover {
    text-decoration: underline;
  }
  