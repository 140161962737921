.coach-page-detail.container {
  border-radius: var(--borderRadius);
  height: auto;
  width: 80vw;
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
  display: flex;
  flex-direction: column;
}

.coach-page-detail.case-id {
  font-size: x-large;
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-detail.case-content-container {
  position: absolute;
  margin-top: 10px;
  top: 200px;
  left: 30px;
  width: 1170px;
  max-height: 490px;
  overflow-y: scroll;
}

.coach-page-detail.case-normal-content-title {
  position: relative;
  margin-top: inherit;
  top: 0px;
  left: 0px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-normal-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-discovery-content-title {
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-discovery-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-interview-content-title {
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-interview-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-action-content-title {
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-action-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-monitor-content-title {
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-monitor-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-closed-content-title {
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: white;
}

.coach-page-detail.case-closed-content {
  position: relative;
  margin-top: inherit;
  color: white;
  white-space: pre-wrap;
}

.coach-page-detail.case-author {
  position: absolute;
  top: 100px;
  left: 30px;
  color: white;
}

.coach-page-detail.case-created {
  position: absolute;
  top: 100px;
  left: 250px;
  color: white;
}

.coach-page-detail.case-update {
  position: absolute;
  top: 100px;
  left: 510px;
  color: white;
}

.coach-page-detail.case-stage {
  position: absolute;
  top: 140px;
  left: 30px;
  color: white;
}

.coach-page-detail.case-dueday {
  position: absolute;
  top: 140px;
  left: 250px;
  color: white;
}

.coach-page-detail.case-severity {
  position: absolute;
  top: 140px;
  left: 510px;
  color: white;
}

hr {
  color: yellowgreen;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 2px;
}

.coach-page-detail.input-text {
  border-radius: inherit;
  background: #d4d4d7;
  padding: inherit;
  white-space: pre-wrap;
  height: 18rem;
  overflow-y: auto;

}
