@import './ThemeVariable.css';

.switch {
  position: relative;
  display: inline-block;
  min-width: 60px;
  height: 34px;
  margin-right: 20px;
}

.switch input {
  display: none;  /* Hide the checkbox */
}

/* Style the slider (off state) */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;  /* Rounded corners */
}

/* Style the slider indicator (circle) */
.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;  /* Make it a circle */
}

/* On mouse-over, add a slight shadow inside the switch */
.switch:hover .slider:before {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

/* When the checkbox is checked, translate the slider to show the ON position */
.switch input:checked + .slider:before {
  transform: translateX(26px);
}

/* Adjust background color of the switch when ON */
.switch input:checked + .slider {
  background-color: green;
}

/* If you want to add "ON" and "OFF" labels inside the switch, you can use the following code */
.switch .slider:after {
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 75%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

.switch input:checked + .slider:after {
  left: 25%;
}