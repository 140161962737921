.statistic-fleet-info-card.container {
  margin-top: 1rem;
  min-width: 100%;
  border-radius: var(--borderRadius);
  background-color: #A6D3DE;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 
    inset 3px 3px 5px rgba(0, 0, 0, 0.3), /* 模擬內凹效果 */
    -px -2px 4px rgba(255, 255, 255, 0.5); /* 模擬外部光源亮邊 */

  padding: var(--cardPadding);
  display: flex;
  flex-direction: column;
}

.statistic-fleet-info-card.title-row {
  display: flex;
  justify-content: space-between;
}


.statistic-fleet-info-card.title {
  font-size: x-large;
  color: black;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.statistic-fleet-info-card.current-time {
  font-size: medium;
  
  color: black;
  font-family: var(--fontFamily);
}

.statistic-fleet-info-card.data-row {
  font-size: 0.9rem;
  display: flex;
  margin-top: 1rem;
  justify-content: space-evenly;
}

.statistic-fleet-info-card.count-title {
  
  text-align: center;
  color: black;
  font-size: medium;
  font-family: var(--fontFamily);
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.statistic-fleet-info-card.count-detail-row {
  display: flex;
}

.statistic-fleet-info-card.duty-text {
  width: auto;
  height: auto;
  font-size: xxx-large;
  color: black;
  font-family: var(--fontFamily);
  margin-left: auto;
  margin-right: auto;

  display: flex; /* 将容器设置为 flex 容器 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 确保文本居中 */
}
.statistic-fleet-info-card.all-text {
  text-align: center;
  margin-top: 10px;
  width: auto;
  height: auto;
  font-size: xxx-large;
  font-family: var(--fontFamily);
}


.statistic-fleet-info-card.count-container {
  position: absolute;
  top: var(--subTitleTopMargin);
  width: 500px;
  height: auto;
  text-align: center;
  color: black;
  font-family: var(--fontFamily);
  background-color: transparent;
}


.statistic-fleet-info-card.driver-container {
  position: absolute;
  height: auto;
  width: 50%;
  display: flex; /* Use the Flexbox layout */
  justify-content: center;
  font-size: large;
  text-align: center;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.statistic-fleet-info-card.device-container {
  position: absolute;
  left: 250px;
  height: auto;
  width: 50%;
  font-size: large;
  display: flex; /* Use the Flexbox layout */
  justify-content: center;
  text-align: center;
  font-family: var(--fontFamily);
  background-color: transparent;
}
.statistic-fleet-info-card.spinner {
  position: absolute;
  top: 280px;
  left: 230px;
  font-weight: bold;
  background-color: transparent;
  font-family: var(--fontFamily);
}

.statistic-fleet-info-card.score-unit {
  position: absolute;
  top: 95px;
  left: 30px;
  width: 260px;
  height: auto;
  text-align: center;
  font-size: large;
  font-weight: normal;
  color: black;
  background-color: transparent;
  font-family: var(--fontFamily);
}

@media (max-width: 1281px) { 
  
  .statistic-fleet-info-card.container {
    min-width: 26rem;
    max-width: 30rem;
    height: 11rem;
    min-height: 11rem;
    max-height: 20rem;
    border-radius: var(--borderRadius);

    padding: var(--cardPadding);
    margin-bottom: var(--cardGap);
    display: flex;
    flex-direction: column;
  }
  .statistic-fleet-info-card.data-row {
    font-size: 0.9rem;
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-evenly;
  }
}