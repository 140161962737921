.fleet-unregister-driver.container {

  padding: var(--cardPadding);
  height: 50rem;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  width:-webkit-fill-available ;
  max-width: 1000px;
  
  margin-left: inherit;
  margin-right: auto;
  overflow: auto;
  overflow-x: auto;
  aspect-ratio: 1080 / 1100;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: stretch;
}

.fleet-unregister-driver.info-row {
  display: flex;
  grid-template-columns: 640px 8fr;
  gap: 20px;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.fleet-unregister-driver.info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.fleet-unregister-driver.info-column-find {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -8rem;
}

@media(max-height:930px) {
  .fleet-unregister-driver.info-column-find {
    margin-left: -15rem;
    margin-top: -5rem;
  }
}

.fleet-unregister-driver.title {
  height: auto;
  flex: 1;
  text-align: left;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-driver.isRegistedBlock {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
  margin-bottom: 20px;
}

.fleet-unregister-driver.info {
  height: auto;
  width: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: x-large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-driver.name {
  height: auto;
  width: auto;
  font-size: large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-unregister-driver.name-field {
  height: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: white;
  border-radius: 7px;
}

.fleet-unregister-driver.image {
  width: 28rem;
  max-width: 50rem;
  height: auto;
  max-height: 45rem;
  aspect-ratio: 1.36;
}

@media (max-width: 1281px) {}

@media (max-height: 1050px) {
  .fleet-unregister-driver.image {
    max-width: 25rem;
    max-height: 20rem;
  }
}

@media (max-height: 930px) {
  .fleet-unregister-driver.image {
    max-width: 20rem;
    max-height: 15rem;
  }


}

.fleet-unregister-driver.match-row {
  display: flex;
  align-items: center;
}

.fleet-unregister-driver.search-description {
  display: flex;
  color: white;
  padding-top: 0px;
  padding-bottom: 20px;
  align-items: center;
}

.fleet-unregister-driver.search-row {
  display: flex;
  padding-top: 0px;
  padding-bottom: 20px;
  align-items: center;
}

.fleet-unregister-driver.group-row {
  margin-left: 140px;
  display: flex;
  align-items: center;
}

.fleet-unregister-driver.duplicate-info {
  height: auto;
  width: 300px;
  margin-left: 20px;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
  text-align: center;
}

.fleet-unregister-driver.match-image {
  width: 23rem;
}

.fleet-unregister-driver.left-button {
  border: none;
  margin-right: 20px;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.fleet-unregister-driver.right-button {
  border: none;
  margin-left: 20px;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.fleet-unregister-driver.duplicate-button {
  width: 100px;
  margin: 5px;
}



.fleet-unregister-driver.button-container {
  display: flex;
  justify-content: flex-end;
}

.fleet-unregister-device.name-field, 
.fleet-unregister-driver.isRegistedBlock {
  margin-bottom: 20px;
}