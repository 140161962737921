.main.background-column {
  width: 100%;
  height: 95vh;
  box-sizing: border-box;
  background-color: var(--bgColorDark);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 95vh; /* 限制最大高度为视窗高度 */

}

.main.background-row {
  width: 100%;
  scrollbar-width: none;
  box-sizing: border-box;
  background-color: var(--bgColorDark);
  display: flex;
  max-height: 100vh; /* 限制最大高度为视窗高度 */

}

.main.navibar {
  z-index: 50;
  height: 100%;
  background-color: var(--bgColorDark);
  /* background-color: green; */
}

.main.main-page {
  width: 100vw;
  min-width: 50vw;
  max-width: 100vw;
  background-color: transparent;
  display: table;
}

.main.sub-navibar {
  padding-top: 50px;
  height: var(--subNaviBarHeight);
  background-color: transparent;
  justify-content: flex-start;
  /* background-color: red; */
}

.main.layout {
  background-color: transparent;
  width: 100vw;
  height:auto;
  /* background-color: yellowgreen; */
  display: table;
  max-height: 100vh; /* 限制最大高度为视窗高度 */
  overflow-y: auto; /* 超出部分滚动 */
}

.main.sidebar {
  width: var(--sideBarWidth);
  height:  48.5rem;
  background-color: #1c202c;
}
.main.sidebar.vehicle {
  width: var(--sideBarWidth);
  height: calc(100% - var(--subNaviBarHeight));
  
  overflow: hidden;
  background-color: #1c202c;
}
.main.sidebar.driver {
  width:var(--sideBarWidth);
  min-width: 200px;
  height: calc(100% - var(--subNaviBarHeight));
  background-color: #1c202c;
}
@media(max-width:1281px){
  .main.sidebar.driver {
    width: 210px;
    min-width: 200px;
    min-height: 51rem;
    height: 56.2rem;
    background-color: #1c202c;
  }
}
.main.sidebar.fleet {
  width: 100%;
  min-width: 400px;

  background-color: var(--bgColorNormal);
  border-radius: 20px;
}
.main.widthsidebar {
  
  width: 100%;
  height: -webkit-fill-available;
  background-color: transparent;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  align-items: stretch;
  scrollbar-width: none;
}

.main.flex-item-half {
  flex: 1; /* Makes each child take up equal width, combined they fill the container */
}

.main.content {
  width: calc(100% - var(--sideBarWidth));
  height: 100vh;
  padding-left: var(--cardGap);
  padding-right: var(--cardGap);
  background-color: transparent;
  display: inline-flex;
}

.main.narrowcontent {

  width:80vw;
  height: -webkit-fill-available;
  max-height: 100vh;

  margin-left: 1rem;
  padding-right: var(--cardGap);
  background-color: transparent;
  display: inline-flex;
}

.main.partialcontent {
  height: auto;
  min-width: 100vw;
  background-color: transparent;
  display: table;
}

.main.singlecontent {
  position: absolute;
  top: 100px;
  left: 0px;
  height: 840px;
  width: 840px;
  background-color: transparent;
}

.main.login {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 1840px;
  height: 840px;
  z-index: 10;
  background-color: var(--bgColorDark);
}

.main.empty {
  text-align: center;
}

.main.empty-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main.empty-verticle-align {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 37%;
  transform: translateY(-50%);
}

.main.empty-image {
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 0px;
}

.main.empty-text {
  height: auto;
  background-color: transparent;
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.main.footer {
  display: flex;
  height: var(--footerHeight);
  border-top: 2px solid #555555;
  justify-content: center;
  color: white;
  margin-left: 69px;
  margin-top: 100px;
  max-height: 5px /* 限制最大高度为 footer 高度 */
}
.main.space{
  width: 69px;
}
body {
  background-color: var(--bgColorDark) !important;
  overflow-y: auto;
}
.main.background-row {
  width: 100%;
  height: calc(100% - var(--footerHeight));
  box-sizing: border-box;
  background-color: var(--bgColorDark);
  display: flex;
  max-height: 100vh; /* 限制最大高度为视窗高度 */

}
.main.flex-item-half.sidebar-container {
  width: 100%; /* Sidebar takes full width */
  height: 100%;
  margin-left: 50px;
  min-width: 30rem;
 
}
/* 平板裝置 (螢幕寬度 768px 以上) */
@media (max-width: 1281px) { 
.main.background-row {
  width: 100%;
  height: calc(100% - var(--footerHeight));
  box-sizing: border-box;
  background-color: var(--bgColorDark);
  display: flex;
  max-height: 100vh; /* 限制最大高度为视窗高度 */
}
.main.sidebar.vehicle {
  width: 13rem;
}

    .main.navibar {
      z-index: 50;
      width: 45px
      /* background-color: green; */
    }
  .main.main-page {
    min-width: 768px; /* 調整最小寬度 */
    max-width: calc(100% - var(--sideBarWidthTablet)); 
 
  }
  .main.sidebar {
      height: 50rem;
  }
  .main.widthsidebar {
    width: calc(var(--sideBarWidthTablet) * 2.3); /* 調整寬度 */
    
  }
  .main-content {
    flex-direction: row; /* Arrange sections horizontally */
  }
  .basic-row > .main.widthsidebar {
    display: flex; /* Enable flexbox for the sidebar container */
    flex-direction: column; /* Stack sidebar and map vertically */
    width: auto; /* Ensure sidebar takes full width */
  }

  .main.narrowcontent {
    margin-left: 0;  /* Remove left margin for smaller screens */
    width: 100%;     /* Ensure map container takes full width */
  }

  .main.flex-item-half.sidebar-container {
    width: 100%; /* Sidebar takes full width */
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .main.flex-item-half.sidebar-container::-webkit-scrollbar {
    width: 0; /* Safari and Chrome */
    display: none; /* Safari and Chrome */
    min-height: 645px;
  }
  .main-section {
    width: 50%; /* Each section takes half the width */
  }

  .sidebar-container {
    flex-direction: row; /* Sidebar and table side-by-side */
    margin-right: 5%;

  }

  .map-container {
    margin-left: var(--cardGap); /* Add spacing between map and sidebar */
  }
  .main.narrowcontent {
    width: calc(100% - (var(--sideBarWidthTablet) * 2.3)); /* 調整寬度 */
  }

  .main.partialcontent {
    height: auto;
    width: 60vw;
    background-color: transparent;
    display: table;
  }
  .main.footer {
    display: flex;
    max-height: 10px;
    border-top: transparent;
    justify-content: center;
    color: transparent;

    max-height: var(--footerHeight); /* 限制最大高度为 footer 高度 */
  }
}

/* 手機裝置 (螢幕寬度 768px 以下) */
@media (max-width: 767px) { 
  .main.background-row {
    width: 100%;
    min-height: 80vh;
    box-sizing: border-box;
    background-color: var(--bgColorDark);
    display: flex;
    max-height: 100vh; /* 限制最大高度为视窗高度 */
    overflow-x: auto; /* 隐藏溢出部分 */
    
  }
  .main.space{
    width:40px;
  }
    .main.main-page {
      min-width: 768px; /* 調整最小寬度 */
      max-width: calc(100% - var(--sideBarWidthTablet)); 
      max-height: 100vh;
    }
    .main.sidebar {
        height: 25vh;
    }
    .main.widthsidebar {
      width: calc(var(--sideBarWidthTablet) * 2.3); /* 調整寬度 */
    }
    .main.content {
      width: calc(100% - var(--sideBarWidthTablet)); /* 調整寬度 */
    }
    .main.narrowcontent {
      width: calc(100% - (var(--sideBarWidthTablet) * 2.3)); /* 調整寬度 */
    }
}