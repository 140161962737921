.navbar.main-container {
  overflow-x: auto;
  background-color: var(--bgColorDark);
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  max-height: 100vh;
  min-width: 6rem;
  margin-left: 10px;
  scrollbar-width: none;
}

.navbar.menu-item-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar.logo-img {
  
  height: auto;
  width: 39px;
  background-color: var(--bgColorDark);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
}

.navbar.logo-version {
  z-index: -1;
  color: gray;
  font-family: var(--fontFamily);
  font-size: small;
  font-weight: normal;
  justify-content: center;
  background-color: transparent;
  margin-left: 15px;
  margin-top: -10px;
  margin-bottom: -15px;
}

.navbar.tab-title {
  width: 100%;
  text-decoration: none;
  background-color: var(--bgColorDark);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
      flex-wrap: nowrap;
}

.navbar.tab-title-hightlight {

  display: flex;
  width: 100%;
  text-decoration: none;
  background-color: var(--bgColorNormal);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

}

.navbar.tab-image {
  left: 15px;
  width: 40px;
  height: auto;
  background-color: transparent;
}

.navbar.tab-image:hover {
  filter: invert(24%) sepia(93%) saturate(7492%) hue-rotate(202deg) brightness(99%) contrast(104%);
}

.navbar.text-title:hover {
  filter: invert(24%) sepia(93%) saturate(7492%) hue-rotate(202deg) brightness(99%) contrast(104%);
}

.navbar.text-title {
  top: 2px;
  left: 20px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: transparent;
  transition: width 2s ease, transform 2s ease; /* transition property to animate transform changes */
  transform: translateX(-100%);
  display: none;
}

.navbar.text-title.expanded {
  width: 10rem;
  transform: translateX(0%);
  /* max-height: 1rem; */
  display: flex;
  margin-right: 0.5rem;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;

}

.navbar.text-title-highlight {
  left: 10px;
  color: #00b454;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.navbar.account-menu {
  position: relative;
  top: 1rem;
  text-align: right;
  z-index: -99999;
  right:-10vw;
  flex-wrap: nowrap;
} 
@media(max-width:1581px){
  .navbar.account-menu {
    position: relative;
    top: 1rem;
    text-align: right;
    z-index: -99999;
    right:-3vw;
    flex-wrap: nowrap;
  } 
}
.navbar.account-name {
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: transparent;
  border: none;
  transition: width 2s ease, transform 2s ease;
  display: none;
}

.navbar.account-name.expanded {
  transform: translateX(0%);
  display: block;
}

.navbar.account-logo {
  height: auto;
  width: auto;
  background-color: transparent;
}

.dropdown-menu.show  {
  background-color: var(--bgColorLight);
  z-index: 9999;
}
a.dropdown-item{
  color:white
}
.dropdown-item:hover{
  color:black
}
.navbar.account-menu-wrapper {
  position: -webkit-sticky; /* 适用于旧版WebKit浏览器 */
  position: absolute;
  top: -10px; /* 确保它在页面顶部固定 */
  left: 75vw; /* 固定在横向75%的位置 */
  z-index: 10; /* 确保它在其他元素之上 */
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 999999;
}

.navbar.notification-button {
  width: auto;
  height: auto;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  font-size: 1.5em;
  color: #fff;
  margin-top: -2rem;
  margin-right: 10px; 
  visibility: hidden; /*等功能好後可移除*/
  /* 控制通知按钮与账号图标之间的距离 
  outline: none;
  */
}

.navbar.notification-button:focus {
  outline: none;
}

@media(max-width:1281px){
 
  /* NavigationBar.css */
 /* NavigationBar.css */

  .navbar.main-container {
    position: fixed; /* Make the navbar fixed to the bottom */
    bottom: 0; /* Position it at the bottom of the viewport */
    width: 100%; /* Full width of the screen */
    max-height: 7rem ;
    scrollbar-width: thin;
    padding: 10px; /* Add padding for visual spacing */
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: space-between; /* Distribute space between elements */
    align-items: center; /* Vertically align items */
    z-index: 1000; /* Ensure it's on top of other content */
    overflow-y: scroll;

  }

  .navbar.menu-item-column {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: flex-end;
  }

  .navbar.tab-title {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
  
  }

  .navbar.logo-img {
    visibility: hidden;
    height: 30px; /* Adjust the height of the logo as needed */
    margin-right: 20px; /* Add spacing between the logo and menu */
  }

  .navbar.text-title.expanded {
    width: 10rem;
    transform: translateX(0%);
    
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: nowrap;
    margin-right: 0.5rem;
  }

  .navbar.tab-title-hightlight {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
  }


}

.navbar.main-container.small-screen {
  position: fixed;
  left: 0;
  bottom: 1rem;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: hidden;
  scrollbar-width: thin; /* Firefox 用于设置滚动条宽度 */
}
.navbar.main-container.small-screen::-webkit-scrollbar {
  width: 8px; /* 滚动条的宽度 */
}

.navbar.main-container.small-screen::-webkit-scrollbar-track {
  background: #b42a2a; /* 滚动条的轨道背景 */
}

.navbar.main-container.small-screen::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条的颜色 */
  border-radius: 4px; /* 滚动条的圆角 */
}

.navbar.main-container.small-screen::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条悬停时的颜色 */
}
.navbar.main-container.small-screen.expanded {
  transform: translateX(0);
}

.navbar.main-container.small-screen .navbar.text-title {
  display: none;
}

.navbar.main-container.small-screen.expanded .navbar.text-title {
  display: inline;
}
.nav-toggle {
  width:min-content;
  display: flex;
  position: fixed; /* 固定定位 */
  bottom: 10rem; /* 距离底部 20 像素 */
  left: 0px; /* 距离左侧 20 像素 */
  background-color: #007bff; /* 按钮背景颜色 */
  color: white; /* 按钮文字颜色 */
  border: none; /* 去掉边框 */
 
  padding: 8px 8px; /* 内边距 */
  cursor: pointer; /* 鼠标悬停样式 */
  z-index: 1000; /* 确保按钮在其他元素之上 */
  flex-wrap: wrap;
}

.nav-toggle:hover {
  background-color: #0056b3; /* 悬停时的背景颜色 */
}
