@import '../ThemeVariable.css';

.ondemand-page {
    background-color: var(--bgColorNormal);
    color: #fff; /* White text */
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
  }
  
  /* Titles */
  .ondemand-page h1 {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .ondemand-page h2 {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Tab buttons */
  button {
    outline: none;
    border: none;
  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .tab-buttons button {
    margin: 0 5px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #303030;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .tab-buttons button:hover {
    background-color: gray;
    transform: scale(1.1);
  }
  
  .tab-buttons button.active {
    background-color: orange;
  }
  
  .control-buttons {
    display: flex;
    gap: 15px; /* Spacing between play, stop, and microphone buttons */
  }

  /* Video container */
  .video-container {
    margin: 20px auto;
    width: 640px;
    height: 480px;
    border: 3px solid #606060;
    border-radius: 10px;
    overflow: hidden;
    position: relative; /* Set position to relative for button positioning */
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    display: block;
  }
  
/* Button container inside video player */
.button-container {
  position: absolute; /* Position relative to the video container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for button dimensions */
  display: flex;
  gap: 20px; /* Space between buttons */
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure buttons are above the video */
}

/* Round-shaped icon buttons */
.icon-button {
  background-color: #404040;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-button:hover {
  background-color: #606060;
  transform: scale(1.1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}