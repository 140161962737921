.review-card.container {
  min-width: 690px;
  width: 690px;

  min-height: 15rem;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: calc(var(--cardPadding) - 2px);
  margin-bottom: var(--cardGap);
  display: flex;
  flex-direction: column;
}
.review-card.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.review-card.option-row {
  display: flex;
  flex-direction: row;
}

.review-card.chart-container {
  position: absolute;
  top: 30px;
  left: 30px;
  height: auto;
  width: 350px;
  background-color: transparent;
}

.review-card.spinner {
  position: absolute;
  top: 30px;
  left: 230px;
  font-weight: bold;
  background-color: transparent;
  font-family: var(--fontFamily);
}

.review-card.no-week-data-text {
  position: relative;
  top: 75px;
  width: auto;
  height: auto;
  font-size: large;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.review-card.circle-row {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.review-card.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80%;
  color: white;
  font-size: medium;
  font-weight: bold;
  text-align: center;
}


.review-card.button {
  align-self: flex-start;
  margin-right: 10px;
  padding: 8px;
  color: gray;
  font-size: large;
  font-family: var(--fontFamily);
  border: none;
  background-color: transparent;
  flex-grow: 0;
  white-space: nowrap;
}

.review-card.button-highlight {
  align-self: flex-start;
  margin-right: 10px;
  padding: 8px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: #5555bb;
}

.review-card.button:hover {
  text-decoration: underline;
}

.review-card.button-highlight:hover {
  text-decoration: underline;
}

.fleet-page-headline.column.right > .review-card.container {
  width: 90vw;
  width: auto;
  z-index: 20;
  min-width:100%;
  min-height: 15rem;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: calc(var(--cardPadding) - 2px);
  margin-bottom: var(--cardGap);
  display: flex;
  flex-direction: column;
}

@media (max-width: 1281px) { 
  
  .review-card.container {
    width: 687px;
   
    min-width:10rem;
    min-height: 15rem;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: calc(var(--cardPadding) - 2px);
    margin-bottom: var(--cardGap);
    display: flex;
    flex-direction: column;
  }
  .fleet-page-headline.column.left > .review-card.container {
    width: 90vw;
    min-width: 30rem;
    max-width: 90vw;
    min-height: 15rem;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: calc(var(--cardPadding) - 2px);
    margin-bottom: var(--cardGap);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .review-card.container.headline {
    width: 90vw;
    width: auto;
    max-width: 40rem;
    min-width:30rem;
    min-height: 15rem;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: calc(var(--cardPadding) - 2px);
    margin-bottom: var(--cardGap);
    display: flex;
    flex-direction: column;
  }
  
  .review-card.title {
    height: auto;
    width: auto;
    font-size: 25px;
    font-weight: none;
    color: white;
    font-family: var(--fontFamily);
    background-color: transparent;
  }
  
  .review-card.option-row {
    display: flex;
    flex-direction: row;
  }
  
  .review-card.chart-container {
    position: absolute;
    top: 30px;
    left: 30px;
    height: auto;
    width: 350px;
    background-color: transparent;
  }
  
  .review-card.spinner {
    position: absolute;
    top: 30px;
    left: 230px;
    font-weight: none;
    background-color: transparent;
    font-family: var(--fontFamily);
  }
  
  .review-card.no-week-data-text {
    position: relative;
    top: 75px;
    width: auto;
    height: auto;
    font-size: large;
    text-align: center;
    color: white;
    font-family: var(--fontFamily);
  }
  
  .review-card.circle-row {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .review-card.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 80%;
    color: white;
    font-size: medium;
    font-weight: bold;
    text-align: center;
  }
  
  
  .review-card.button {
    align-self: flex-start;
    margin-right: 10px;
    padding: 8px;
    color: gray;
    font-size: large;
    font-family: var(--fontFamily);
    border: none;
    background-color: transparent;
    flex-grow: 0;
    white-space: nowrap;
  }
  
  .review-card.button-highlight {
    align-self: flex-start;
    margin-right: 10px;
    padding: 8px;
    color: white;
    font-size: large;
    font-family: var(--fontFamily);
    border-radius: 7px;
    background-color: #5555bb;
  }
  
  .review-card.button:hover {
    text-decoration: underline;
  }
  
  .review-card.button-highlight:hover {
    text-decoration: underline;
  }
  
}