.vehicle-page-map-custom-marker-cluster {
  border-radius: 50%;
  color: black;
  font-size: medium;
  font-family: var(--fontFamily);
  text-align: center;
  width: 50px;
  height: 50px;
  background-image: radial-gradient(
    rgba(250, 95, 22, 0.7),
    rgba(231, 173, 13, 0.7),
    rgba(220, 235, 13, 0.7)
  );
}
@-moz-document url-prefix() {
  .stastic-page-map.container {
    margin-left: -2rem;
  }
}
.stastic-page-map.container {
  position: relative;
  z-index: 0;
  width: -webkit-fill-available;
}
.vehicle-page-map.container {
  position: relative;
  z-index: 0;
  width: -webkit-fill-available;
  margin-bottom: 2rem;
}

@-moz-document url-prefix() {
  .vehicle-page-map.container {
    margin-bottom: -2rem;
  }
}
.vehicle-page-map.map-container {
  margin-top: -2rem;
  height: 48.3rem;
  width: -webkit-fill-available;
  min-height: 10rem;
  overflow-x : hidden;
 
  border-radius: 7px;
  z-index: 0;
}

@-moz-document url-prefix() {
  @media(max-width:1470px){
    .vehicle-page-map.map-container {
      margin-bottom: 2rem;
    }
  }
}  

@media(max-width:1470px){
  .vehicle-page-map.map-container {
    margin-top: -2rem;

    width: -webkit-fill-available;
    min-height: 10rem;
    overflow-x : hidden;
   
    border-radius: 7px;
    z-index: 0;
  
  
  }
}


.vehicle-page-map.instant_location-button.live {
  position: absolute;
  top: 80px;
  left: 20px;
  z-index: 99;
  width: 35px;
  height: 35px;
  border-style: solid;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.7);
}

.vehicle-page-map.instant_location-button.unlive {
  position: relative;
  top: 10rem;
  left: 1rem;
  z-index: 99;
  width: 35px;
  height: 35px;
  border-style: solid;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.7);
}

.vehicle-page-map.instant_location-button.live div{
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  border-color: green;
  border-width: 3px;
  border-style: solid;
  margin-left: -2px;
}

.vehicle-page-map.instant_location-button.unlive div{
  width: 15px;
  height: 15px;
  background-color: gree;
  border-radius: 50%;
  border-color: black;
  border-width: 3px;
  border-style: solid;
  margin-left: -2px;
}

tooltip{
  position: absolute;
  top:400px;
  left: 400px;
}

.vehicle-page-map-popup .leaflet-popup-content-wrapper {
  height: 90px;
  width: 260px;
  padding-left: 60px;
  padding-top: 5px;
  font-family: var(--fontFamily);
  font-size: large;
  text-align: center;
  border-radius: 7px;
  color: var(--textColor);
  background: var(--bgColorNormal);
}

.vehicle-page-map-popup .leaflet-popup-content-wrapper:hover {
  background: var(--bgColorLight);
  cursor: pointer;
}

.vehicle-page-map-popup.lost-connection-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.7); /* Semi-transparent red background */
  color: white;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  z-index: 1000; /* Ensure it's above the map */
}

@media (max-width: 1281px) { 
    .driver-page-table-card.outer-container {
      border-radius: var(--borderRadius);
      margin-bottom: var(--cardGap);
      border: none;
      width: 98%;
      height: 30vh;
      padding: var(--cardPadding);
      background-color: var(--bgColorNormal);
      min-width:auto;
      margin-left: initial;
      margin-right:initial;
  }
  .vehicle-page-map.map-container {
    min-height:20rem;
    min-width:300px;
    margin-left: -0.5rem;
    margin-right: auto;
    border-radius: 7px;
    z-index: 0;
   
    max-width: 50rem;
    position: relative;

    

  }

  .vehicle-page-map.instant_location-button.unlive {
    position: relative;
    top: 10rem;
    left: 0.1rem;
    z-index: 99;
    width: 35px;
    height: 35px;
    border-style: solid;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.7);
  }
  
}

.current-time {
  color: #FFFFFF;
}

.green-pin {
  background-color: #34C759;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  
  top: -10px;
  left: -5px;
}

.green-pin::after {
  content: '';
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}

/* VehiclePageMap.css */

.moving-pin {
  width: 15px; /* 根据需要调整宽度 */
  height: 15px; /* 根据需要调整高度 */
  background-color: blue;/* 动态点的颜色 */
  border-radius: 50%; /* 使其为圆形 */
  border: 2px solid #fff; /* 添加白色边框 */
  position: relative;
  z-index: 3;
}

.moving-pin::after {
  content: '';
  position: absolute;
  top: 35%;
  left: 35%;
  width: 5px; /* 根据需要调整宽度 */
  height: 5px; /* 根据需要调整高度 */
  background-color: #fff; /* 内部小点的颜色 */
  border-radius: 50%; /* 使其为圆形 */
  transform: translate(-50%, -50%); /* 中心对齐 */
  animation: pulse 1s infinite; /* 添加脉动动画 */
   z-index: 3;
}
.moving-pin-legend{
  width: 20px; /* 根据需要调整宽度 */
  height: 20px; /* 根据需要调整高度 */
  background-color: blue;/* 动态点的颜色 */
  border-radius: 50%; /* 使其为圆形 */
  border: 2px solid #fff; /* 添加白色边框 */
  position: relative;
  z-index: 1;
}

.moving-pin-legend::after {
  content: '';
  position: absolute;
  top: 35%;
  left: 40%;
  width: 5px; /* 根据需要调整宽度 */
  height: 5px; /* 根据需要调整高度 */
  background-color: #fff; /* 内部小点的颜色 */
  border-radius: 50%; /* 使其为圆形 */
  transform: translate(-50%, -50%); /* 中心对齐 */
  animation: pulse 1s infinite; /* 添加脉动动画 */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.start-point .start-pin {
  width: 20px; /* 根据需要调整宽度 */
  height: 20px; /* 根据需要调整高度 */
  background-color: green; /* 起始点颜色 */
  border-radius: 50%; /* 使其为圆形 */
  border: 2px solid white; /* 边框颜色 */
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  z-index: 1;
}

.end-point .end-pin {
  width: 20px; /* 根据需要调整宽度 */
  height: 20px; /* 根据需要调整高度 */
  background-color: red; /* 结束点颜色 */
  border-radius: 50%; /* 使其为圆形 */
  border: 2px solid white; /* 边框颜色 */
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  z-index: 1;
}

.legend {
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  font-size: 16px; /* 增加字体大小 */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* 稍微增加间距 */
}

.legend-item > div {
  margin-right: 10px;
}

.legend-item > span {
  font-size: 16px; /* 确保文字大小一致 */
}
.start-pin, .end-pin, .moving-pin {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.start-pin {
  background-color: green;
}

.end-pin {
  background-color: red;
}


