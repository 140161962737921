/* 預設樣式：適用於較大的螢幕 */
.vehicle-utilization-container {
    display: flex;
  }
  
  .graphs-container {
    flex: 1; /* 圖表區域佔據可用空間 */
  }
  
  .table-container {
    width: 100%; /* 表格區域佔據一半寬度 */
    height: -webkit-fill-available;
  }
  
  /* 螢幕高度小於 1020px 時的樣式 */
  @media (max-width: 1281px) {
    .vehicle-utilization-container {
      flex-direction: column; /* 將圖表和表格改為垂直排列 */
    }
  
    .table-container {
      width: 100%; /* 表格區域佔據整個寬度 */
    }
  }

  .table-frame-container {
    width: 50%; /* 調整寬度 */
    height: 300px; /* 設定固定高度，或根據需要調整 */
    overflow-y: auto; /* 允許垂直滾動 */
  }
  
  .table-frame {
    /* 可選：添加一些內邊距或樣式 */
    padding: 10px;
  }
  