:root {
  --map-height: -webkit-fill-available;
  --map-border-radius: 16px;
  --map-margin-top: 1rem;
  --map-min-height: 40rem;
  --map-margin-left: 4rem;
  
}

.statistic-page-map.container {
  border-radius: var(--map-border-radius);
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-left: 30px;
}
@media (max-width: 2480px) {
  .statistic-page-map.container {
  }
}
@media (max-width: 1981px) {
  .statistic-page-map.container {
  }
}
@media (max-width: 1615px) {
  .statistic-page-map.container {
  }
}
.statistic-page-map-custom-marker-cluster {
  border-radius: 50%;
  color: white;
  padding-top: 8px;
  border-style: double;
  font-size: medium;
  font-family: var(--fontFamily);
  text-align: center;
  width: 50px;
  height: 50px;
  background-color: black;
}

.statistic-page-map-popup .leaflet-popup-content-wrapper {
  height: 90px;
  width: 260px;
  padding-left: 60px;
  padding-top: 5px;
  font-family: var(--fontFamily);
  font-size: large;
  text-align: center;
  border-radius: 7px;
  color: var(--textColor);
  background: var(--bgColorNormal);
}

.driver-page-map-popup .leaflet-popup-content-wrapper:hover {
  background: var(--bgColorLight);
  cursor: pointer;
}


@media (max-width: 1281px) {
  .statistic-page-table-card.table-container {
    display: table;
  }
  .statistic-page-map.container {
    height: calc(100% - 12px);
    border-radius: var(--map-border-radius);
    margin-top: var(--map-margin-top);
    overflow: hidden;
    position: absolute;
    width: 45vw;
    min-height: var(--map-min-height);
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-height: 1326px) {
    .statistic-page-map.container {
      margin-bottom: 2vh;
  
    }
  }
}

@media (max-width: 1200px) {
  .statistic-page-map.container {
    width: 45vw;
    min-width: 395px;
    min-height: 20rem;
  }
  @media (max-height: 1326px) {
    .statistic-page-map.container {
      margin-bottom: 2vh;
    }
  }
}

@media (max-width: 1100px) {
  .statistic-page-map.container {
    width: 40vw;
    min-width: 395px;
  }
  @media (max-height: 1326px) {
    .statistic-page-map.container {
      margin-bottom: 1vh;
    }
  }
}

@media (max-width: 1000px) {
  .statistic-page-map.container {
    width: 35vw;
    min-width: 20rem;
    min-height: 645px;
  }
}
@media (max-width: 800px) {
    .statistic-page-map.container {
      width: 80vw;
      height: 45rem;
      border-radius: 16px;
      margin-top: 1rem;
      position: relative;
      min-height: 45rem;
      margin-left:3rem;
      left: auto;
      max-height: 47.5rem;
    }
}

@keyframes flash {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.custom-div-icon.flashing div {
  animation: flash 3s infinite;
}
