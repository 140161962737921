@import './ThemeVariable.css';

.create-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.delete-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
}

.save-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.clear-button {
  width: var(--buttonWidth);
  min-width:4rem;
  height: 45px;
  border-radius: 7px;
}

.cancel-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.edit-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
}

.yes-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.no-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.reset-button {
  width: var(--buttonWidth);
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.long-button {
  width: 200px;
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}

.custom-button {
  width: auto;
  height: 45px;
  border-radius: 7px;
  margin-left: 10px;
}
@media(max-width:1280px){
  .clear-button {
    width: var(--buttonWidth);
    min-width:4rem;
    height: 45px;
    border-radius: 7px;
  
    margin-right: auto;
    margin-left: 2rem;
  }
  .reset-button {
    width: var(--buttonWidth);
    min-width:4rem;
    height: 45px;
    border-radius: 7px;
    margin-left: 10px;
  }

.save-button {
  width: var(--buttonWidth);
  height: 45px;
  min-width:4rem;

  border-radius: 7px;

}
}