@import './ThemeVariable.css';

.sidebar.top-simple-container {
  position: relative;
  margin: 0px;

  min-width: 100px;
  margin-top: 10px;
}

.sidebar.top-simple-container.event-page {
  position: relative;
  margin: 0px;
  background-color: var(--bgColorNormal);
  min-width: 100px;
}

.sidebar.search-icon {
  position: absolute;
  margin-top: 28px;
  margin-left: 35px;
  width: 16px;
  height: 16px;
  background-color: transparent;
  z-index: 1;
}

.sidebar.searchbar {
  position: relative;
  width: calc(100% - 40px);
  height: 35px;
  margin: 20px;
  min-width: 270px;
  padding-left: 40px;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: rgb(105, 109, 105);
  border: none;
}

.sidebar.searchbar.vehicle{
  position: relative;
  width: auto;
  min-width: 10rem;
  max-width: 20rem;

 
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: rgb(105, 109, 105);
  border: none;
}
.sidebar.searchbar.driver{
  position: relative;
  width: auto;
  min-width: 10rem;
  max-width: 20rem;

 
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: rgb(105, 109, 105);
  border: none;
}
#searchbar-placeholder-style::placeholder {
  color: white;
  opacity: 0.5;
  font-weight: lighter;
}

.sidebar.orderby-button-group {
  position: relative;
  top: 102px;
  left: 22px;
  width: 346px;
  height: 42px;
}

.sidebar.orderby-button {
  position: relative;
  height: 42px;
  width: 173px;
  border: none;
  border-radius: 0px;
}
.sidebar.device-info-container.fleetpage{
  position: relative;
  width: 100%;
  height: auto;
  max-height: 42.2em;
  min-height: 41.2rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--bgColorNormal);
}
.sidebar.unregister-driver-container{
  height: 50rem;
  overflow: hidden;
  overflow-y: auto;
  margin-left: 2rem;
  border-radius: 10px;
  background-color: #1c202c;
}
@media(max-width: 1470px){
  .sidebar.device-info-container {
    position: relative;
    width: 100%;
    height: 40.6rem;
    overflow-x: hidden;
    overflow-y: auto;
    }

}
.sidebar.device-info-container {
  position: relative;
  width: 100%;
  height: 50.6rem;
  overflow-x: hidden;
  overflow-y: auto;
  
}


.sidebar.device-info-container::-webkit-scrollbar {
  width: 12px;
}
.sidebar.device-info-container:-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.sidebar.device-info-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar.fleet-info-container {
  height: calc(85vh - 14rem);
  overflow-y: auto;
}

.sidebar.device-info-image {
  margin-top: -5px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

/* Common styling for all Device Info Cards */
.sidebar.device-info-card {
  height: 50px;
  border: none;
  border-radius: 0;
  border-bottom: solid 2px rgba(128, 128, 128, 0.2);
  background-color: var(--bgColorNormal);
}

/* Fleet-specific styling (if needed to differentiate) */
.sidebar.device-info-card.fleetcard {
  background-color: var(--bgColorNormal); /* Already set in common style */
}

/* Hover state for Device Info Cards */
.sidebar.device-info-card:hover {
  background-color: var(--bgColorNormal); /* Same color on hover, adjust if you want different hover color */
}

/* Device Info Title Text */
.sidebar.device-info-title {
  padding-left: 10px;
  color: white;
  font-size: medium;
  font-family: var(--fontFamily);
}

/* Repeated hover state - Keeping the single declaration */
.sidebar.device-info-card:hover {
  background-color: var(--bgColorNormal);
}

.sidebar a {
  overflow-y: auto; 
  text-decoration: none;
  color: inherit; /* To keep the original color */
}
.sidebar.unregister-driver-info-title {
  top: 13px;
  left: 30px;
  color: white;
  font-size: medium;
  font-family: var(--fontFamily);
}

.sidebar.register-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bgColorLight);
}

.sidebar.register-button {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 5px;
  border: none;
  border-radius: 7px;
  font-family: var(--fontFamily);
  text-align: center;
  background-color: transparent;
}

.sidebar.fleet-option-container {
  position: relative;
  left: 0px;
  height: 50px;
  width: 100%;
  padding-left: 22px;
  background-color: var(--bgColorLight);
}

.sidebar-toggle-button { 
 
  /* ... your existing button styles ... */
  width: 100%; /* Adjust the width as needed */
  display: flex;
  justify-content: center; /* Center the icon horizontally */
  border-radius: 7px;
  align-items: center;     /* Center the icon vertically */
}

.sidebar-toggle-button svg { /* Target the Font Awesome svg element */
  font-size: medium; /* Adjust the icon size as needed */
}

@media (max-width: 1281px) { 

  .sidebar.device-info-container.fleetpage{
    position: relative;
    width: 13rem;
    height: auto;
    max-height: 42.2em;
    min-height: 40.2rem;
    overflow-x: hidden;
    overflow-y: auto;

  }
  .sidebar.device-info-container {
    position: relative;
    width: 13rem;
    height: 51.2rem;
    background-color: var(--bgColorNormal);


    overflow-x: hidden;
    overflow-y: auto;

  }
  
  .sidebar.searchbar.vehicle{
    position: relative;
    width: auto;
    min-width: 5rem;
    max-width: 10rem;
  
   
    text-align: left;
    font-size: medium;
    font-family: var(--fontFamily);
    border-radius: 7px;
    background-color: rgb(105, 109, 105);
    border: none;
  }
  .sidebar.searchbar.driver{
    position: relative;
    width: auto;
    min-width: 5rem;
    max-width: 10rem;
  
   
    text-align: left;
    font-size: medium;
    font-family: var(--fontFamily);
    border-radius: 7px;
    background-color: rgb(105, 109, 105);
    border: none;
  }
  .sidebar-toggle-button {
    /* ... your existing button styles ... */
    min-width: 26rem;
    max-width: 30rem;
    height: auto;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center;     /* Center the icon vertically */
  }
  
  .sidebar-toggle-button svg { /* Target the Font Awesome svg element */
    font-size: medium; /* Adjust the icon size as needed */
  }
  .sidebar.top-simple-container.fleetpage {
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-direction: column;
  }
  .sidebar.fleet-info-container {
    height: calc(85vh - 17rem);
  }
  .sidebar.searchbar.fleetpage {
    position: relative;
    width: auto;
    min-width: 26rem;
    max-width: 30rem;
    margin: 20px;
    margin-right: auto;
    margin-left: auto;
   
    text-align: left;
    font-size: medium;
    font-family: var(--fontFamily);
    border-radius: 7px;
    background-color: rgb(105, 109, 105);
    border: none;
  }
}


.device-info-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar .device-info-title.car-num {
  width: 150px; /* 固定宽度 */
  color: white;
}

.sidebar .device-info-title.last-on-duty {
  width: 100px; /* 固定宽度 */
  color: white;
}

.sidebar .device-info-title.car-type {
  padding-left: 10px; /* 根据需要调整，与 car-num 的宽度匹配 */
  width: 230px;
  color:white
}


@media(max-width:1539px){
  .sidebar .device-info-title.last-on-duty {
    margin-left: 0rem;
  }
}
