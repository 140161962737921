.settings-notification.category-title-row {
  margin-top: var(--controlVMargin);
  margin-left: 300px;
  margin-bottom: var(--controlVMargin);
  display: flex;
  flex-direction: row; /* This is unnecessary here because row is the default direction, but included for clarity */
  justify-content: flex-start; 
}

.settings-notification.title {
  font-size: x-large;
  color: white;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: transparent;
  margin-bottom: var(--controlVMargin);
}

.settings-notification.item-title {
  position: relative;
  height: auto;
  font-size: large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.settings-notification.description {
  position: relative;
  height: auto;
  padding-bottom: 25px;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.settings-notification.option-name {
  width: 22.5vw;
  margin-left: 20px;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}


.settings-notification.toggle-button {
  background-color: lightgray;
  color: black;
}

/* Highlighted (active) state of the toggle button */
.settings-notification.toggle-button.active {
  background-color: blue;
  color: white;
}

.settings-notification.lock-switch {
  color: white;
}

.settings-notification.event-row {
  width: auto;
  display: flex;
}
