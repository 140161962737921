.test-case-page-chart-card.container {
  width: 620px;
  height: 830px;
  border-radius: var(--borderRadius);
  padding: var(--cardPadding);
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--bgColorNormal);
}

.test-case-page-chart-card.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.test-case-page-chart-card.title-image {
  position: relative;
  top: 15px;
  left: 40px;
  height: 50px;
  width: 50px;
  background-color: transparent;
}

.test-case-page-chart-card.chart-title {
  position: relative;
  left: 0px;
  width: 400px;
  height: auto;
  font-size: large;
  color: white;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.test-case-page-chart-card.chart-subtitle {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
}

.test-case-page-chart-card.chart-subtitle::before {
  width: 400px;
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 0%;
  transform: translateX(0%);
  background-color: #949292;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: medium;
  white-space: normal;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.test-case-page-chart-card.chart-subtitle:hover::before  {
  opacity: 1;
}

.test-case-page-chart-card.chart-grid {
  display: grid;
  grid-template-columns: 310px 310px;
}

.test-case-page-chart-card.chart {
  position: relative;
  top: 0px;
  left: -40px;
  padding-top: 10px;
  background-color: transparent;
}

.test-case-page-chart-card.tooltip {
  background-color: white;
  padding: 10px;
  opacity: 0.7;
  font-family: var(--fontFamily);
  border-color: var(--bgColorLight);
  border-width: 1px;
  border-style: solid;
}

.test-case-page-chart-card.progress-line {
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  width: 2px;
  height: 50px;
  background: white;
  z-index: 50;
}

.test-case-page-chart-card.no-data {
  position: absolute;
  top: 290px;
  left: 160px;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 0px;
}

.test-case-page-chart-card.no-data-text {
  position: absolute;
  top: 460px;
  left: 135px;
  width: 200px;
  height: auto;
  background-color: transparent;
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.test-case-page-chart-card.timeline {
  position: relative;
  margin-left: 58px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 410px;
  height: 50px;
  background-color: #555555;
  border-radius: 5px;
  overflow: visible;
  z-index: 1;

}

.test-case-page-chart-card.event {
  position: absolute;
  height: 100%;
}

.test-case-page-chart-card.event-type-container {
  display: flex;
  align-items: center; /* Vertically center align */
  justify-content: center; /* Horizontally center align */
}

.LDW {
  background-color: #804136;  /* Tomato color for "ldw" flag */
}

.HMW {
  background-color: #2e6c2e;  /* LimeGreen color for "hmw" flag */
}

.TTC {
  background-color: #204b75;  /* DodgerBlue color for "ttc" flag */
}

.ldw-forground {
  color: #FF6347;  /* Tomato color for "ldw" flag */
  margin-right: 10px;
}

.hmw-forground {
  color: #32CD32;  /* LimeGreen color for "hmw" flag */
  margin-right: 10px;
}

.ttc-forground {
  color: #1E90FF;  /* DodgerBlue color for "ttc" flag */
  margin-right: 10px;
}

.test-case-page-chart-card.progress-line-LDW {
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  width: 1px;
  height: 50px;
  background: #FF6347;  /* Tomato color for "ldw" flag */
  z-index: 51;
}

.test-case-page-chart-card.progress-line-HMW {
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  width: 1px;
  height: 50px;
  background: #32CD32;  /* LimeGreen color for "hmw" flag */
  z-index: 51;
}

.test-case-page-chart-card.progress-line-TTC {
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  width: 1px;
  height: 50px;
  background: #1E90FF;  /* DodgerBlue color for "ttc" flag */
  z-index: 51;
}
