@import './ThemeVariable.css';

.basic-row {
  display: flex;
  max-width: 100vw;
  max-height: 100vh;

}

.basic-row.headline {
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  height: -webkit-fill-available;
}
.basic-row-button {
  display: flex;
  max-width: 100vw;
  max-height: 100vh;

}


.center-row {
  display: flex; /* Use Flexbox to create a flex container */
  justify-content: center; /* Center the items horizontally within the container */
}
 .statistic-page-info-card.data-row 
.basic-column > .basic-row 
 {
  justify-content: center;
}

.main.layout > .basic-row {
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  height: none;
}

@media (max-width: 1281px) { 
  .basic-row {
    display: flex;
    max-width: 100vw;
  
  }
  .center-row {
    display: flex; /* Use Flexbox to create a flex container */
    justify-content: center; /* Center the items horizontally within the container */
  }

  .navbar.main-container >.basic-column  > .basic-row{
    max-width: 0px;
    max-height: 0px;
  }
}