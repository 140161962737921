
.button-group-container {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    margin-top: -0.8rem;
  }

  .button-group.bs {
    display: flex;
    margin-left: var(--controlHMargin);

  }
  

  .button-group .btn {
    white-space: nowrap;
  }