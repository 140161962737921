.event-page-chart-card.container {
 
  min-width: 10rem;
  max-width: 60rem;
  width: 860px;
  height:56.3rem;
  border-radius: var(--borderRadius);
  padding: var(--cardPadding);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--bgColorNormal);
}
/* @media(max-width:1500px){
  .event-page-chart-card.container {

    min-width: 10rem;
    max-width: 60rem;
    width: 860px;
    height:80vh;
    border-radius: var(--borderRadius);
    padding: var(--cardPadding);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--bgColorNormal);
  }
  
} */
.event-page-chart-card.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.event-page-chart-card.title-image {
  position: relative;
  top: 15px;
  left: 40px;
  height: 50px;
  width: 50px;
  background-color: transparent;
}

.event-page-chart-card.chart-title {
  position: relative;
  top: 10px;
  left: 0px;
  width: 400px;
  height: auto;
  padding-top: 10px;
  font-size: large;
  color: white;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.event-page-chart-card.chart-subtitle {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
}

.event-page-chart-card.chart-subtitle::before {
  width: 400px;
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 0%;
  transform: translateX(0%);
  background-color: #949292;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: medium;
  white-space: normal;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.event-page-chart-card.chart-subtitle:hover::before  {
  opacity: 1;
}

.event-page-chart-card.chart-grid {
  display: grid;
  grid-template-columns: 310px 310px;
  justify-items: stretch;
  justify-content: space-evenly;
}

.event-page-chart-card.chart {
  position: relative;
  top: 0px;
  left: -40px;
  padding-top: 10px;
  background-color: transparent;
}

.event-page-chart-card.tooltip {
  background-color: white;
  padding: 10px;
  opacity: 0.7;
  font-family: var(--fontFamily);
  border-color: var(--bgColorLight);
  border-width: 1px;
  border-style: solid;
}

.event-page-chart-card.progress-line {
  position: absolute;
  display: inline-block;
  top: 20px;
  width: 2px;
  height: 125px;
  background: white;
}

.event-page-chart-card.no-data {
  position: absolute;
  top: 290px;
  left: 160px;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 0px;
}

.event-page-chart-card.no-data-text {
  position: absolute;
  top: 460px;
  left: 135px;
  width: 200px;
  height: auto;
  background-color: transparent;
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}
@media (max-width:1281px) { 
 
  .event-page-chart-card.title {
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}



}
