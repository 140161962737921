.vehicle-page-timeline-card.container {
 
  min-height: 350px;
  width: 690px;
  max-height: 460px;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: var(--cardPadding);
}

.vehicle-page-timeline-card.title {
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: var(--textColor);
  font-family: var(--fontFamily);
}

.vehicle-page-timeline-card.subtitle {
  position: absolute;
  top: 80px;
  left: 22px;
  width: auto;
  font-size: large;
  color: var(--textColor);
  font-family: var(--fontFamily);
}

.vehicle-page-timeline-card.title-image {
  position: absolute;
  top: 15px;
  left: 20px;
  height: 50px;
  width: 50px;
}

.vehicle-page-timeline-card.legend-container {
  position: relative;
  top : -50px;
  left : 80%;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.vehicle-page-timeline-card.graph-container {
  position: absolute;
  top: 130px;
  left: 55px;
  width: 600px;
  height: auto;
  border-radius: 0px;
  background-color: transparent;
}

.vehicle-page-timeline-card.active {
  background-color: #10b43e;
  color: white;
}

.vehicle-page-timeline-card.offline {
  background-color: gray;
  color: white;
}

.vehicle-page-timeline-card.idle {
  background-color: rgb(255, 201, 22);
  color: white;
}

.vehicle-page-timeline-card.test {
  background-color: rgb(155, 21, 22);
  color: white;
}

.vehicle-page-timeline-card.date-picker-container {
  position: relative;
  top:50px;
  left: 0px;
  background-color: transparent;
}

.vehicle-page-timeline-card.date-picker-button {
  position: relative;
  width: 240px;
  height: 38px;
  top: 11px;
  left: 0px;
  padding-top: 0px;
  border-radius: 7px;
  text-align: center;
  font-size: large;
  font-weight: light;
  color: White;
  border: transparent;
  font-family: var(--fontFamily);
  background-color: var(--bgColorLight);
}

.vehicle-page-timeline-card.date-picker-button:hover {
  background-color: var(--bgColorDark);
}

.vehicle-page-timeline-card.label-container {
  position: absolute;
  top: 140px;
  left: -15px;
  width: 1320px;
  height: 100px;
  background-color: transparent;
}

@media (max-width:1281px) { 
  .vehicle-page-timeline-card.container {
    max-height: 200px;
    margin: auto;
   
    max-width: 690px;
    
    width: 690px;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: var(--cardPadding);
  }
  
}
@-moz-document url-prefix() {
  @media(max-width:1470px){
    .vehicle-page-timeline-card.container {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}  

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.button-container .btn {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.button-container .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.button-container .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.button-container .btn i {
  margin-right: 8px;
  font-size: 18px;
}

.button-container .btn-primary i {
  color: #fff;
}

.button-container .btn-primary:active {
  transform: scale(0.98);
}
