.css-z8fhq1-MuiDataGrid-columnHeaders{
  background-color:darkgreen;
  color:white;
}

.driver-page-table-card.outer-container {
  border-radius: var(--borderRadius);
  margin-bottom: var(--cardGap);
  border: none;
  width: auto;
  margin-top: auto;
  width: 98%;
  min-width: 690px;
  height: 507px;
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
  margin-left: auto;
  margin-right: auto;
}


.driver-page-table-card.table-container {
  border-radius: var(--borderRadius);
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 410px;
  background-color: var(--bgColorNormal);

}

.driver-page-table-card.title {
  top: var(--titleTopMargin);
  left: var(--titleLeftMargin);
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}


.driver-page-table-card.table {
  border-radius: var(--borderRadius);
}

.driver-page-table-card.cell {
  color: white;
}

.driver-page-table-card.info-list {
  position: relative;
  border-right: transparent;
  border-left: transparent;
  border-top: solid;
  border-top-width: 3px;
  border-top-color: var(--bgColorLight);
  background-color: var(--bgColorNormal);
  background-color: transparent;
}


.driver-page-table-card.info-content-user {
  position: relative;
  font-weight: bold;
  color: lightskyblue;
  font-size: large;
  font-family: var(--fontFamily);
  display: inline;
}

.driver-page-table-card.info-content-driver {
  position: relative;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
  display: inline;
}


.driver-page-table-card.info-name:hover {
  color: red;
  font-weight: bold;
}

.driver-page-table-card.info-comment {
  position: relative;
  font-weight: 500;
  color: yellowgreen;
  font-size: large;
  font-family: var(--fontFamily);
  display: inline;
}

.driver-page-table-card.text {
  position: relative;
  top: 20px;
  width: auto;
  height: auto;
  font-size: large;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}
