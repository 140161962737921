.test-case-page-video-card.container {
  width: 860px;
  height: calc(100% - 370px);
  border-radius: var(--borderRadius);
  margin-top: var(--cardGap);
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
}

.test-case-page-video-card.video-1-column {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start; /* This will ensure that items are stacked from the top */
  padding-left: 160px;
  padding-right: 160px;
}

.test-case-page-video-card.video-column {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start; /* This will ensure that items are stacked from the top */
}

.test-case-page-video-card.video-row {
  display: flex;
  flex-grow: 1;
  margin-top: 15px;
}

.test-case-page-video-card.videoWrapper {
  flex: 1;
  position: relative;
}

.test-case-page-video-card.videoWrapper > div {  /* Assuming VideoPlayer renders to a div */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bgColorNormal);
}

.test-case-page-video-card.title {
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: var(--textColor);
  font-family: var(--fontFamily);
}

.test-case-page-video-card.title-image {
  position: absolute;
  top: 15px;
  left: 20px;
  height: 50px;
  width: 50px;
}

.test-case-page-video-card.video1 {
  position: relative;
  margin: 0 auto;
  width: 440px;
  height: 330px;
  top: 5px;
  background-color: transparent;
}

.test-case-page-video-card.video2 {
  position: relative;
  float: left;
  margin-left: 20px;
  text-align: left;
  width: 400px;
  height: 300px;
  top: 5px;
  background-color: transparent;
}

.test-case-page-video-card.video3 {
  position: relative;
  float: left;
  width: 272px;
  height: 204px;
  top: 65px;
  margin-left: 10px;
  background-color: transparent;
}

.test-case-page-video-card.play-button {
  width: auto;
  height: auto;
  background-color: transparent;
  border: 0px;
}

.test-case-page-video-card.pause-button {
  width: auto;
  height: auto;
  background-color: transparent;
  border: 0px;
}

.test-case-page-video-card.each-slide {
  display: flex;
  width: 320px;
  height: 240px;
}

.test-case-page-video-card.each-slide > div {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.test-case-page-video-card.each-slide > div img {
  width: 100%;
  height: 100%;
}

.test-case-page-video-card.no-video-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.test-case-page-video-card.no-video {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: 0px;
}

.test-case-page-video-card.no-video-text {
  margin-top: var(--controlVMargin);
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.video-react .video-react-big-play-button.video-react-big-play-button-hide { display: none; }