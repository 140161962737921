:root {
  --naviBarWidth: 69px;
  --sideBarWidth: 300px;
  --subNaviBarHeight: 90px;
  --mainLayoutHeight: 80vh;
  --mainContentWidth: 70vw;
  --sideBarContainerHeight: 94px;
  --sideBarEventContainerHeight: 150px;
  --cardGap: 20px;
  --cardPadding: 15px;
  --controlHMargin: 20px;
  --controlVMargin: 20px;
  --borderRadius: 16px;
  --inputBorderRadius: 5px;
  --inputBackgroundColor: rgba(64, 64, 64, 128);
  --footerHeight: 50px;
  --bgColorDark: #13191e;
  --bgColorNormal: #212a35;
  --bgColorLight: #323e4f;
  --bgColorHighLight: #00337a;
  --metallic-light-blue: linear-gradient(to bottom, #a8d4dc,#cbe8ee);
  --color_theme_1:#A6D3DE;
  --colorGreen: #00c459;
  --colorGreenDark: #1c7544;
  --colorBlue: #00a0d9;
  --textColor: white;
  --fontFamily: 'Roboto', sans-serif;
  --titleTopMargin: 10px;
  --titleLeftMargin: 10px;
  --subTitleTopMargin: 60px;
  --buttonWidth: 80px;
  --buttonHeight: 45px;
}


body.dark-mode {
  --color_theme_1: #212a35; /* 黑夜模式 */
}

.main.navibar::-webkit-scrollbar,
.main.layout::-webkit-scrollbar,
.sidebar.analysis-page-event-info-container::-webkit-scrollbar,
.sidebar.device-info-container::-webkit-scrollbar,
.sidebar.event-info-container::-webkit-scrollbar,
.sidebar.unregister-driver-container::-webkit-scrollbar,
.sidebar.fleet-info-container::-webkit-scrollbar,
.event-page-chart-card.container::-webkit-scrollbar,
.statistic-page-head-line-card.container::-webkit-scrollbar,
.statistic-page-table-card.table-container::-webkit-scrollbar,
.statistic-rank-container.table-container::-webkit-scrollbar,
.coach-page-edit.container::-webkit-scrollbar,
.full-page-container::-webkit-scrollbar,
.driver-page-table-card.table-container::-webkit-scrollbar,
.vehicle-page-table-card.table-container::-webkit-scrollbar,
.driver-page-event-list.card-container::-webkit-scrollbar,
.vehicle-page-setting.container::-webkit-scrollbar,
.vehicle-page-event-list.card-container::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}

.main.navibar::-webkit-scrollbar-track,
.main.layout::-webkit-scrollbar-track,
.sidebar.analysis-page-event-info-container::-webkit-scrollbar-track,
.sidebar.device-info-container::-webkit-scrollbar-track,
.sidebar.event-info-container::-webkit-scrollbar-track,
.sidebar.unregister-driver-container::-webkit-scrollbar-track,
.sidebar.fleet-info-container::-webkit-scrollbar-track,
.event-page-chart-card.container::-webkit-scrollbar-track,
.statistic-page-head-line-card.container::-webkit-scrollbar-track,
.statistic-page-table-card.table-container::-webkit-scrollbar-track,
.statistic-rank-container.table-container::-webkit-scrollbar-track,
.coach-page-edit.container::-webkit-scrollbar-track,
.full-page-container::-webkit-scrollbar-track,
.driver-page-table-card.table-container::-webkit-scrollbar-track,
.vehicle-page-table-card.table-container::-webkit-scrollbar-track,
.driver-page-event-list.card-container::-webkit-scrollbar-track,
.vehicle-page-setting.container::-webkit-scrollbar-track,
.vehicle-page-event-list.card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: #777777;
}

.main.navibar::-webkit-scrollbar-thumb,
.main.layout::-webkit-scrollbar-thumb,
.sidebar.analysis-page-event-info-container::-webkit-scrollbar-thumb,
.sidebar.device-info-container::-webkit-scrollbar-thumb,
.sidebar.event-info-container::-webkit-scrollbar-thumb,
.sidebar.unregister-driver-container::-webkit-scrollbar-thumb,
.sidebar.fleet-info-container::-webkit-scrollbar-thumb,ya
.event-page-chart-card.container::-webkit-scrollbar-thumb,
.statistic-page-head-line-card.container::-webkit-scrollbar-thumb,
.statistic-page-table-card.table-container::-webkit-scrollbar-thumb,
.statistic-rank-container.table-container::-webkit-scrollbar-thumb,
.coach-page-edit.container::-webkit-scrollbar-thumb,
.full-page-container::-webkit-scrollbar-thumb,
.driver-page-table-card.table-container::-webkit-scrollbar-thumb,
.vehicle-page-table-card.table-container::-webkit-scrollbar-thumb,
.driver-page-event-list.card-container::-webkit-scrollbar-thumb,
.vehicle-page-setting.container::-webkit-scrollbar-thumb,
.vehicle-page-event-list.card-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  background-color: #555555;
}

#fade-in {
  font-family: var(--fontFamily);
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spinner {
  color: white;
}