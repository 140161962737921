@import './ThemeVariable.css';

.full-page-container {
  width: 90vw;
  height: 85vh;
  max-height: 100vh;
  min-height: 50vh;
  padding: var(--cardPadding);
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
 
  overflow-x: hidden;
  overflow-y: auto;
}
.full-page-container-geofencing{
  width: 90vw;
  height: 85vh;
  max-height: 100vh;
  min-height: 50vh;
  padding: var(--cardPadding);
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  min-width: 838px;
  overflow-x: auto;
  overflow-y: hidden;
}

.right-align-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.card-container {
  color: white;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: var(--cardPadding);
}

.card-title {
  white-space:nowrap;
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
  margin-bottom: var(--controlVMargin);

}

.left-card-margin {
  margin-left: var(--cardGap);
}

.top-card-margin {
  margin-top: var(--cardGap);
}

.left-control-margin {
  margin-left: var(--controlHMargin);
}


.top-control-margin {
  margin-top: var(--controlVMargin);
}

.grow-width {
  min-width: fit-content;
  display: inline-flex;
  padding-left: var(--cardGap);
}

.full-width {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.full-width-with-sidebar {
  width: calc(100% - 150px);
}
.full-width-with-sidebar-driver{
  width: calc(100% - 150px);
  min-width: 1150px;
}


.relative {
  position: relative;
}

.overflow-y {
  overflow-y: auto;
}