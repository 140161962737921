/* For event page sidebar*/

.sidebar.test-case-page-top-container {
  position: relative;
  height: 150px;
  width: var(--sidebarWidth);
  background-color: var(--bgColorLight);
  padding: var(--cardPadding);
  display: flex;
  flex-direction: column;  
}

.sidebar.test-case-page-search-icon {
  position: absolute;
  margin-top: 10px;
  margin-left: 15px;
  width: 45px;
  height: 45px;
  background-color: transparent;
  z-index: 1;
}

.sidebar.test-case-page-searchbar {
  position: relative;
  width: calc(100% - 40px);
  height: 35px;
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 40px;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: rgb(105, 109, 105);
  border: none;
}

.sidebar.test-case-page-searchbar-to {
  margin-top: 10px;
  margin-left: -15px;
  width: 100%;
  text-align: center;
  font-size: medium;
  color: white;
  font-family: var(--fontFamily);
  height: auto;
}

.sidebar.test-case-page-searchbar-dropdown {
  margin-top: 12px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: medium;
  font-family: var(--fontFamily);
  text-align: right;
  background-color: var(--bgColorLight);
  color: white;
  border: none;
  border-radius: 7px;
}

.sidebar.test-case-page-searchbar-dropdown:hover,
.sidebar.test-case-page-searchbar-dropdown:focus {
  background-color: var(--bgColorNormal);
}

.sidebar.test-case-page-searchbar-dropdown-icon {
  width: 25px;
  filter: brightness(0) invert(1);
  background-color: transparent;
  z-index: 1;
}

.sidebar.test-case-page-searchbar-dropdown-image {
  position: relative;
  top: 15px;
  left: 10px;
  width: 65px;
  height: 45px;
  filter: brightness(0) invert(1);
  background-color: transparent;
  z-index: 1;
}

.sidebar.test-case-page-searchbar-dropdown-card {
  height: 65px;
  align-content: flex-end;
  border-bottom: solid rgba(255, 255, 255, 0.5) 2px;
  background-color: transparent;
}

.sidebar.test-case-page-searchbar-dropdown-card:first-child {
  border-top: solid rgba(255, 255, 255, 0.5) 2px;
}

.sidebar.test-case-page-searchbar-dropdown-card:hover {
  background-color: var(--colorGreenDark);
}

.sidebar.test-case-page-searchbar-dropdown-menu {
  width: 200px;
  text-align: center;
  font-family: var(--fontFamily);
  color: white;
  background-color: var(--bgColorNormal);
  z-index: 1;
}

.sidebar.test-case-page-searchbar-dropdown-text {
  height: 55px;
  width: 120px;
  font-size: large;
  text-align: center;
  padding-top: 15px;
  padding-left: 0px;
  font-family: var(--fontFamily);
  color: white;
  background-color: transparent;
}

.sidebar.test-case-page-datepicker-start-button {
  position: absolute;
  top: 82px;
  left: 22px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  text-align: center;
  background-color: var(--colorGreen);
  border: none;
}
.sidebar.test-case-page-datepicker-end-button {
  position: absolute;
  top: 82px;
  left: 213px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  text-align: center;
  background-color: var(--colorGreen);
  border: none;
}
.sidebar.test-case-info-container {
  position: relative;
  top: 0px;
  left: 0px;
  width: var(--sideBarWidth);
  position: relative;
  width: 100%;
  height: 680px;
  /* height: calc(100vh - var(--subNaviBarHeight) - var(--sideBarEventContainerHeight));
  min-height: 670px; */
  overflow-x: hidden;
  overflow-y: auto;
  background-color: transparent;

}

.sidebar.test-case-info-card {
  position: relative;
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 0px;
  border-bottom: solid 2px rgba(128, 128, 128, 0.2);
  background-color: var(--bgColorNormal);
}

.sidebar.test-case-info-card:hover {
  background-color: var(--bgColorDark);
}

.sidebar.test-case-info-image {
  position: absolute;
  top:10px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 0px;
  background-color: transparent;
}

.sidebar.test-case-info-title {
  position: absolute;
  top: 10px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
}

.sidebar.test-case-info-driver-name {
  position: absolute;
  top: 40px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
}


.sidebar.test-case-info-subtitle {
  position: absolute;
  top: 75px;
  color: rgba(128, 128, 128, 1.0);
  font-size: large;
  text-align: right;
  max-width: 300px;
  font-family: var(--fontFamily);
}

.sidebar.test-case-info-event-type {
  position: absolute;
  top: 75px;
  width: 60px;
  color: rgba(128, 128, 128, 1.0);
  font-size: x-small;
  font-family: var(--fontFamily);
  text-align: center;
}

.sidebar.edit-switch {
  display: flex;
  justify-content: space-between;
  color: white;
  padding-top: 5px;
  padding-bottom: 40px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  margin: 0px;
  background-color: var(--bgColorLight);
}

.sidebar.test-case-page-datepicker-start-button:hover,
.sidebar.test-case-page-datepicker-end-button:hover,
.sidebar.test-case-info-button:hover {
  background-color: var(--colorGreenDark);
}

.sidebar.test-case-info-checkbox-text {
  color: white;
  font-size: large;
}

.light-filter-button {
  margin: 5px;
  border: 1px solid gray;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #888888;
  color: #CCCCCC;
}

.light-filter-button.active {
  background-color: darkblue;
  color: white;
}

.location-filter-button {
  margin: 5px;
  border: 1px solid gray;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #888888;
  color: #CCCCCC;
}

.location-filter-button.active {
  background-color: darkgreen;
  color: white;
}

.button-container {
  display: flex;
  justify-content: center; /* centers items horizontally in the container */
  align-items: center;     /* if you also want to center them vertically */
  flex-wrap: wrap;         /* allows items to wrap to the next line if necessary */
}