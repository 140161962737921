.event-page-info-card.container {
  width: 860px;
  height: 350px;
  min-width: 860px;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
}

.event-page-info-card.title {
  position: absolute;
  top: 25px;
  left: 80px;
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.event-page-info-card.title-image {
  position: absolute;
  top: var(--titleTopMargin);
  left: var(--titleLeftMargin);
  height: 50px;
  width: 50px;
  background-color: transparent;
}

.event-page-info-card.image {
  position: relative;
  top: 5px;
  left: 25px;
  width: 200px;
  height: 200px;
  background-color: transparent;
}

.event-page-info-card.subtitle {
  position: absolute;
  top: 80px;
  left: 150px;
  width: auto;
  height: auto;
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.event-page-info-card.subtitle-driver {
  position: absolute;
  padding-top: 20px;
  top: 100px;
  left: 150px;
  width: 250px;
  height: 45px;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  border-radius: 7px;
  font-family: var(--fontFamily);
  /* background-color: #df6467; */
}

/* .event-page-info-card.subtitle-driver:hover {
  background-color: #b89670;
  cursor: pointer;
} */

.event-page-info-card.subtitle-vehicle {
  position: absolute;
  position: absolute;
  padding-top: 20px;
  top: 135px;
  left: 150px;
  width: 250px;
  height: 45px;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  border-radius: 7px;
  font-family: var(--fontFamily);
  /* background-color: #5ca2a2; */
}

/* .event-page-info-card.subtitle-vehicle:hover {
  background-color: #93a28b;
  cursor: pointer;
} */

.event-page-info-card.event-text {
  position: absolute;
  top: 240px;
  left: 30px;
  width: auto;
  height: auto;
  max-width: 300px;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.event-page-info-card.driver-text {
  position: absolute;
  top: 70px;
  left: 490px;
  width: auto;
  height: auto;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.event-page-info-card.vehicle-text {
  position: absolute;
  top: 235px;
  left: 490px;
  width: auto;
  height: auto;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.event-page-info-card.image-driver {
  position: absolute;
  top: 100px;
  left: 30px;
  width: 100px;
  height: 100px;
  background-color: transparent;
}

.event-page-info-card.image-vehicle {
  position: absolute;
  top: 210px;
  left: 370px;
  width: 100px;
  height: 100px;
  background-color: transparent;
}

.event-page-info-card.no-gps {
  position: absolute;
  top: 70px;
  left: 550px;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 0px;
}

.event-page-info-card.no-gps-text {
  position: absolute;
  top: 240px;
  left: 525px;
  width: 200px;
  height: auto;
  background-color: transparent;
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}


.event-page-video-card.container {
  position: relative;
  padding: 20px;
}

.sync-checkbox-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.sync-checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}

.sync-checkbox-container label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.event-page-video-card.video-1-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-page-video-card.video-column {
  display: flex;
  flex-direction: column;
}


.event-page-video-card.videoWrapper {
  position: relative;

  max-width: 600px;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.event-page-video-card.no-video {
  width: 150px;
  height: 150px;
}


.event-page-video-card.video-1-column {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


.event-page-video-card.video-row {
  display: flex;

  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.event-page-video-card.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.event-page-video-card.videoWrapper {

  width: 500px;
  height: 500px;
 
  border-radius: 5px;
  overflow: hidden;
}
@media(max-width:1281px){}
.event-page-video-card.no-video-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.event-page-video-card.no-video {
  width: 150px;
  height: 100px;
}

.event-page-video-card.no-video-text {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.event-page-video-card.play-button,
.event-page-video-card.pause-button {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99999;
}

.event-page-video-card.play-button img,
.event-page-video-card.pause-button img {
  width: 100%;
  height: 100%;
  z-index: 99999;
}
/* ... (your existing styles) */

/* Container for the driver-vehicle section */
.event-page-info-card.driver-vehicle-section {
  display: grid; /* Use grid layout */
  grid-template-columns: auto 1fr; /* Two columns: auto for icon, 1fr for details */
  align-items: center; /* Vertically align items */
  gap: 1rem; /* Space between columns */
}


/* Details container */
.event-page-info-card.driver-vehicle-details {
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack items vertically */
}

/* Adjust the link styles as needed */
.event-page-info-card .subtitle-driver,
.event-page-info-card .subtitle-vehicle {
  margin: 0; /* Remove default margin */
}

@media (max-width: 1281px) { 

  
  
}
