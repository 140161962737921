.coach-page-edit.container {
  width: 80vw;
  border-radius: var(--borderRadius);
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  margin-left: 0%;
  min-width: 50rem;

  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.coach-page-edit.case-id {
  font-size: x-large;
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.option-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.coach-page-edit.driver-title {
  font-size: large;
  color: white;
  margin-right: var(--controlHMargin);
  flex-wrap: nowrap;
  width: 100px;
}

.coach-page-edit.option-dropdown {
  font-size: large;
  color: white;
  margin-right: var(--controlHMargin);
}

.coach-page-edit.pop-severity-dropdown-title {
  font-size: large;
  color: white;
  margin-right: var(--controlHMargin);
  width: 140px;
}
@media(max-width:1720px){
  .coach-page-edit.pop-severity-dropdown-title {

    width: 130px;
  }
}
@media(max-width:1280px){
  .coach-page-edit.pop-severity-dropdown-title {

    width: 100px;
  }
}

.coach-page-edit.case-subject-title {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-subject {
  font-size: large;
  background-color: white;
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-author {
  position: relative;
  top: 100px;
  left: 30px;
  color: white;
}

.coach-page-edit.case-created {
  position: relative;
  top: 100px;
  left: 250px;
  color: white;
}

.coach-page-edit.case-update {
  position: relative;
  top: 100px;
  left: 510px;
  color: white;
}

.coach-page-edit.case-stage {
  position: relative;
  top: 140px;
  left: 30px;
  color: white;
}

.coach-page-edit.case-dueday {
  position: relative;
  top: 140px;
  left: 250px;
  color: white;
}

.coach-page-edit.case-severity {
  position: relative;
  top: 140px;
  left: 510px;
  color: white;
}

.coach-page-edit.submit-button {
  position: relative;
}

.coach-page-edit.pop-status-dropdown {
  position: relative;
}

.coach-page-edit.driver-dropdown {
  font-size: medium;
  background-color: white;
  border-radius: 7px;
  width:10rem;
}

.coach-page-edit.pop-severity-dropdown {
  position: relative;
}

.coach-page-edit.date-picker-row {
  display: flex;
  align-items: center;
}

.coach-page-edit.pop-status-datepicker-title {
  font-size: large;
  color: white;
  margin-right: var(--controlHMargin);
}

.coach-page-edit.pop-status-datepicker {
  position: relative;
  border-radius: 7px;
  height: 50px;
}

.coach-page-edit.pop-monitor-datepicker {
  position: relative;
}

.coach-page-edit.pop-close-datepicker {
  position: relative;
}

.coach-page-edit.case-content-container {
  position: relative;
}

.coach-page-edit.case-normal-content-title {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-normal-content {
  background-color: rgb(64, 64, 64);
  color: white;
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-discovery-content-title {
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-discovery-content {
  background-color: rgb(64, 64, 64);
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-interview-content-title {
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-interview-content {
  background-color: rgb(64, 64, 64);
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-action-content-title {
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-action-content {
  background-color: rgb(64, 64, 64);
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-monitor-content-title {
  color: white;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-monitor-content {
  background-color: rgb(64, 64, 64);
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-closed-content-title {
  color: white;
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.case-closed-content {
  background-color: rgb(64, 64, 64);
  border-radius: 7px;
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.dueday-picker {
  margin-bottom: var(--controlVMargin);
}

.coach-page-edit.button-group {
  display: flex;
  justify-content: flex-end;
}

.coach-page-edit.control-button {
  margin-left: 0.5rem; /* Optional: adjust margin to space buttons */
}