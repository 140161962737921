/*.test-case-page-info.container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: transparent;
}*/

.test-case-page-info.main-row {
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
}

.test-case-page-info.info-column {
  height: auto;
  background-color: transparent;
  flex-direction: column;
  justify-content: flex-start;
}

.test-case-page-info.chart-column {
  margin-left: var(--cardGap);
  height: auto;
  background-color: transparent;
}