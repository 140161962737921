.statistic-page-head-line-card.container {
  min-width: 100%;
  height: 100%;
  min-height: 24rem;
  border-radius: var(--borderRadius);
  padding: var(--cardPadding);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--bgColorNormal);
}


.statistic-page-head-line-card.title {
  top: var(--titleTopMargin);
  left: var(--titleLeftMargin);
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  max-height: 30rem;
  font-family: var(--fontFamily);
  background-color: transparent;
}


.statistic-page-head-line-card.content-container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex; /* Use the Flexbox layout */
  flex-direction: column;
  background-color: transparent;
}

.statistic-page-head-line-card.text {
  position: relative;
  width: auto;
  height: auto;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
}

.statistic-page-head-line-card.spinner {
  font-weight: bold;
  background-color: transparent;
  font-family: var(--fontFamily);
}

@media(max-width:1281px) {
  .statistic-page-head-line-card.container {
    width: 90vw;
    min-width: 30rem;
    max-width: 90vw;
   
    
    border-radius: var(--borderRadius);
    padding: var(--cardPadding);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: var(--bgColorNormal);
  }
  @-moz-document url-prefix() {
    .statistic-page-head-line-card.container {
      margin-bottom: 1rem;
    }
  }
}