.test-case-page-info-card.container {
  width: 860px;
  height: 350px;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
}

.test-case-page-info-card.title {
  position: absolute;
  top: 25px;
  left: 80px;
  height: auto;
  width: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.test-case-page-info-card.title-image {
  position: absolute;
  top: var(--titleTopMargin);
  left: var(--titleLeftMargin);
  height: 50px;
  width: 50px;
  background-color: transparent;
}

.test-case-page-info-card.image {
  position: relative;
  top: 5px;
  left: 25px;
  width: 200px;
  height: 200px;
  background-color: transparent;
}

.test-case-page-info-card.subtitle {
  position: absolute;
  top: 80px;
  left: 150px;
  width: auto;
  height: auto;
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.test-case-page-info-card.subtitle-driver {
  position: absolute;
  padding-top: 20px;
  top: 100px;
  left: 150px;
  width: 250px;
  height: 45px;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  border-radius: 7px;
  font-family: var(--fontFamily);
  /* background-color: #df6467; */
}

/* .test-case-page-info-card.subtitle-driver:hover {
  background-color: #b89670;
  cursor: pointer;
} */

.test-case-page-info-card.software-version {
  width: 380px;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  border-radius: 7px;
  font-family: var(--fontFamily);
  /* background-color: #5ca2a2; */
}

/* .test-case-page-info-card.subtitle-vehicle:hover {
  background-color: #93a28b;
  cursor: pointer;
} */

.test-case-page-info-card.event-text {
  width: auto;
  height: auto;
  max-width: 350px;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.test-case-page-info-card.driver-text {
  position: absolute;
  top: 70px;
  left: 490px;
  width: auto;
  height: auto;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.test-case-page-info-card.vehicle-text {
  position: absolute;
  top: 235px;
  left: 490px;
  width: auto;
  height: auto;
  font-size: large;
  font-weight: normal;
  color: white;
  font-family: var(--fontFamily);
}

.test-case-page-info-card.image-driver {
  position: absolute;
  top: 100px;
  left: 30px;
  width: 100px;
  height: 100px;
  background-color: transparent;
}

.test-case-page-info-card.image-vehicle {
  position: absolute;
  top: 210px;
  left: 370px;
  width: 100px;
  height: 100px;
  background-color: transparent;
}

.test-case-page-info-card.no-gps {
  position: absolute;
  top: 70px;
  left: 550px;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 0px;
}

.test-case-page-info-card.no-gps-text {
  position: absolute;
  top: 240px;
  left: 525px;
  width: 200px;
  height: auto;
  background-color: transparent;
  font-size: large;
  font-weight: normal;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.testTableContainer {
  width: 100%;      /* Full width of the parent container */
  height: 100%;     /* Full height of the parent container */
  overflow-y: auto; /* Enable vertical scrolling when required */
  overflow-x: hidden;
}
  

