.vehicle-page-info.container {
  height: 56.5rem;
  min-width: 907px;
  max-height: inherit;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
@media(max-width:1281px){
  .vehicle-page-info.container {
    height: 57.2rem;
 
  }
}
.vehicle-page-info.overview-container {
  max-width: 80vw;
  max-height: 20mwh;
  height: 20mwh;
  margin-bottom: 20px;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 13fr 12fr;;
}

.vehicle-page-info.data-container {
  width: 100%;
  height: auto;
  max-height: 50vh;
  margin-bottom: 20px;
  background-color: transparent;
  display: flex;
  grid-template-columns: 1fr 13fr 12fr;;
}

.vehicle-page-info.image-column {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  background-color: transparent;
}

.vehicle-page-info.datepicker-row {
  display: flex;
  align-items: center;
}

.vehicle-page-info.left-arrow {
  margin-left: 0px;
  margin-right: 17px;
  border: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.vehicle-page-info.right-arrow {
  margin-left: 0px;
  border: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.vehicle-page-info.datepicker-column {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.vehicle-page-info.name {
  box-sizing: border-box;
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.vehicle-page-info.edit {
  margin-left: 20px;
  border: none;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.vehicle-page-info.driver-id {
  box-sizing: border-box;
  font-size: medium;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.vehicle-page-info.manager {
  box-sizing: border-box;
  font-size: medium;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.vehicle-page-info.device-info-button {
  top: 50px;
  left: 740px;
  width: 100px;
  position: absolute;
}
.vehicle-page-info.device-info-button:hover {
  background-color: var(--colorGreenDark);
}

.vehicle-page-info.datepicker-button {
  position: absolute;
  left: 0px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--colorGreen);
  border: none;
}
@media(max-width:1281px){

  .vehicle-page-info.datepicker-column {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}
}