.vehicle-page-setting.container {
    min-width: 40rem;
    width: 60vw;
    height: 56.5rem;
    scrollbar-width: none;
    padding: var(--cardPadding);
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    background-color: var(--bgColorNormal);
    margin-left: 0%;
    overflow-y: auto;
}

.vehicle-page-setting.device-Id {
  font-size: x-large;
  color: white;
}
@media(max-width:1281px){
    .vehicle-page-setting.container {
        min-width: 40rem;
        width: 70vw;
        height: 56.5rem;
        scrollbar-width: none;
        padding: var(--cardPadding);
        border-radius: var(--borderRadius);
        display: flex;
        flex-direction: column;
        background-color: var(--bgColorNormal);
        margin-left: 0%;
        overflow-y: auto;
    }
}
.vehicle-page-setting.driver-Id-content {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin)
}

.vehicle-page-setting.device-Id-content {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin)
}
 
.vehicle-page-setting.title{
    font-size: large;
    color:white;
    display: flex;
    align-items: center;
}

.vehicle-page-setting.subject{
    font-size: medium;
    color:white;
    display: flex;
    align-items: center;
}

.vehicle-page-setting.input{
    font-size: large;
    background-color: white;
    border-radius: 7px;
    margin-bottom: var(--controlVMargin)
}

.vehicle-page-setting.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: gray;
    border-style: dashed;
    background-color: transparent;
    color: white;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-radius: 7px;
}
.vehicle-page-setting.upload-area{
    position: relative;
    width: 600px;
    height: 100px;
    display: flex;
    flex-direction: column;
    color: white;
}

.vehicle-page-setting.image
{
    max-width: 450px;
    max-height: auto;
    border-radius: 8px;
}

.vehicle-page-setting.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  