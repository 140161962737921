/*.event-page-info.container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: transparent;
}*/

.event-page-info.main-row {
  height: 55.7rem;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  min-width: 1743px;
}
/* @media(max-width:2125px){
  .event-page-info.main-row {
   
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  }
} */
/* @media(max-width:1281px){
  .event-page-info.main-row {
    height: 50rem;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  }
} */
.event-page-info.info-column {
  height: 20vh;
  background-color: transparent;
  flex-direction: column;
  justify-content: flex-start;
  
}

.event-page-info.chart-column {
  max-width:80vw;
  margin-left: var(--cardGap);
  height: auto;
  background-color: transparent;
}