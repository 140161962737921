.empty-layout.piechart {
  position: relative;
  text-align: center;
  background: url("../../../textures/empty_pie.png");
  width: 160px;
  height: 160px;
  margin: auto;
  margin-top: 3rem;
}

.empty-layout.piechart-text {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    align-items:center;
    justify-content:center;
    font-size: large;
    text-align: center;
    color: white;
    font-family: var(--fontFamily);
  }
  