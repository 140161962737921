.device-card {
    background-color: var(--bgColorNormal);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    color: white;
  }
  
  .device-card:hover {
  }
  
  .device-card-content {
    background-color: var(--bgColorNormal);
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
  
  .device-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .row-left-align {
    justify-content: flex-start;
  }
  
  .row-padding {
    justify-content: space-between;
  }
  
  .device-info-item {
    display: flex;
    align-items: center;
  }
  
  .car-number {
    width: 180px;
    font-size: medium;
    font-weight: bold;
  }
  
  .driver-info {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  
  .driver-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid white;
  }
  
  .driver-name {
    font-size: medium;
    font-weight: bold;
  }
  
  .geocoding {
    font-size: medium;
    color: #ccc;
    text-align: left; /* Left-aligned */
  }
  
  .right-aligned-item {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    flex: 1;
  }
  
  .speed-info, .last-on-duty-time {
    font-size: medium;
    color: #ccc;
    width: 100px; /* Fixed width */
    text-align: right; /* Right aligned */
  }
  
  .status-point {
    display: inline-block;
    border-radius: 50%;
  }
  
  .camera-button {
    background: transparent; /* Transparent background */
    border: none;            /* Remove border */
    cursor: pointer;         /* Pointer cursor */
    color: white;            /* White foreground */
    display: inline-flex;    /* Ensure it stays inline */
    align-items: center;     /* Center align icon */
    justify-content: center; /* Center content */
    margin-left: 8px;        /* Add spacing from car number */
    font-size: 1.3em;          /* Match font size with the surrounding text */
    transform: translateY(5px);
  }
  
  .camera-button:hover {
    color: #f8f9fa; /* Slightly lighter white on hover */
  }
  
  .camera-button:focus {
    outline: none; /* Remove focus outline */
  }
  