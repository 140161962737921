/* For event page sidebar*/

.sidebar.event-page-top-container {
  position: relative;
  height: 150px;
  width: var(--sidebarWidth);
  background-color: var(--bgColorLight);
  padding: var(--cardPadding);
  display: flex;
  flex-direction: column;  
}

.sidebar.event-page-search-icon {
  position: absolute;
  margin-top: 10px;
  margin-left: 15px;
  width: 45px;
  height: 45px;
  background-color: transparent;
  z-index: 1;
}

.sidebar.event-page-searchbar {
  position: relative;
  width: calc(100% - 40px);
  height: 35px;
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 40px;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  border-radius: 7px;
  background-color: rgb(105, 109, 105);
  border: none;
}

.sidebar.event-page-searchbar-to {
  margin: auto;
  width: 1rem;
  text-align: center;
  font-size: medium;
  color: white;
  font-family: var(--fontFamily);
  height: 1rem;
}

.sidebar.event-page-searchbar-dropdown {
  margin-top: 19px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: medium;
  font-family: var(--fontFamily);
  text-align: right;
  background-color: var(--bgColorLight);
  color: white;
  border: none;
  border-radius: 7px;
}

.sidebar.event-page-searchbar-dropdown:hover,
.sidebar.event-page-searchbar-dropdown:focus {
  background-color: var(--bgColorNormal);
}

.sidebar.event-page-searchbar-dropdown-icon {
  width: 25px;
  filter: brightness(0) invert(1);
  background-color: transparent;
  z-index: 1;
}

.sidebar.event-page-searchbar-dropdown-image {
  position: absolute;
  right: 0;
  margin-top: 10px;
  width: 65px;
  height: 45px;
  filter: brightness(0) invert(1);
  background-color: transparent;
  z-index: 1;
}

.sidebar.event-page-searchbar-dropdown-card {
  height: fit-content;
  align-content: flex-end;
  border-bottom: solid rgba(255, 255, 255, 0.5) 2px;
  background-color: transparent;
  scrollbar-width: none;
}

.sidebar.event-page-searchbar-dropdown-card:first-child {
  border-top: solid rgba(255, 255, 255, 0.5) 2px;
}

.sidebar.event-page-searchbar-dropdown-card:hover {
  background-color: var(--colorGreenDark);
}

.sidebar.event-page-searchbar-dropdown-menu {
  width: 17em;
  height: 66vh;
  text-align: center;
  font-family: var(--fontFamily);
  color: white;
  background-color: var(--bgColorNormal);
  z-index: 99999999;
  overflow-x: auto;
}

.sidebar.event-page-searchbar-dropdown-text {
  height: 55px;
  width: fit-content;
  font-size: large;
  text-align: center;
  padding-top: 0; /* 移除 padding-top 以确保垂直居中 */
  padding-left: 0;
  font-family: var(--fontFamily);
  color: white;
  overflow: auto;
  background-color: transparent;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.sidebar.event-page-searchbar-dropdown-card > .row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
  justify-content: center;
}
.sidebar.event-page-datepicker-start-button {
  position: absolute;
  top: 82px;
  left: 22px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  text-align: center;
  background-color: var(--colorGreen);
  border: none;
}
.sidebar.event-page-datepicker-end-button {
  position: absolute;
  top: 82px;
  left: 213px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  text-align: center;
  background-color: var(--colorGreen);
  border: none;
}
.sidebar.event-info-container {

  top: 0px;
  left: 0px;

  position: relative;
  width: 100%;
  height: 46.7rem;
  /* height: calc(100vh - var(--subNaviBarHeight) - var(--sideBarEventContainerHeight));
  min-height: 670px; */
  overflow-x: hidden;
  overflow-y: auto;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: transparent;
}
@media(max-width:2125px){
  .sidebar.event-info-container {

    top: 0px;
    left: 0px;

    position: relative;
    width: 100%;
   
    /* height: calc(100vh - var(--subNaviBarHeight) - var(--sideBarEventContainerHeight));
    min-height: 670px; */
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }
}
.sidebar.event-info-card {
  position: relative;

  width: 100%;
  height: 100px;
  border: none;
  border-radius: 0px;
  border-bottom: solid 2px rgba(128, 128, 128, 0.2);
  background-color: var(--bgColorNormal);
}

.sidebar.event-info-card:hover {
  background-color: var(--bgColorDark);
}

.sidebar.event-info-image {
  position: absolute;
  top:10px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 0px;
  background-color: transparent;
}

.sidebar.event-info-title {
  position: absolute;
  top: 10px;
  left: 104px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
}

.sidebar.event-info-driver-name {
  position: absolute;
  top: 40px;
  left: 104px;
  color: white;
  font-size: large;
  font-family: var(--fontFamily);
}


.sidebar.event-info-subtitle {
  position: absolute;
  top: 75px;
  left: 104px;
  color: rgba(128, 128, 128, 1.0);
  font-size: large;
  text-align: right;
  max-width: 300px;
  font-family: var(--fontFamily);
}

.sidebar.event-info-event-type {
  position: absolute;
  top: 75px;
  width: 60px;
  color: rgba(128, 128, 128, 1.0);
  font-size: x-small;
  font-family: var(--fontFamily);
  text-align: center;
}

.sidebar.edit-switch {
  display: flex;
  justify-content: space-between;
  color: white;
  padding-top: 5px;
  padding-bottom: 40px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  margin: 0px;
  background-color: var(--bgColorLight);
}

.sidebar.event-page-datepicker-start-button:hover,
.sidebar.event-page-datepicker-end-button:hover,
.sidebar.event-info-button:hover {
  background-color: var(--colorGreenDark);
}

