.collapse.alert {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

.collapse.alert-style {
  width: 560px;
  left: 30px;
}
