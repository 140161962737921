.sub-nav-bar.main-title {
  color: white;
  margin-top: 20px;
  margin-left: 20px;
  font-size: x-large;
}

.sub-nav-bar.subtab {
  width: auto;
  margin-bottom: 10px;
  text-decoration: none;
  background-color: transparent;
  min-width: max-content;
}


.sub-nav-bar.sub-text-title {
  color: white;
  margin-left: 20px;
  font-size: x-large;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.sub-nav-bar.sub-text-title-highlight {
  color: white;
  margin-left: 20px;
  height: 40px;
  font-size: x-large;
  font-family: var(--fontFamily);
  background-color: transparent;
  border-bottom: 4px solid #ffffff;
}