.driver-utilization-widget {
    width: 690px ;
    color: white;
    padding: 20px;
    background-color:  var(--bgColorNormal);
    border-radius: var(--borderRadius);
    min-height: 33rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s, transform 0.3s;
    margin: auto;
  }

 
  .no-data-message {
    text-align: center;       /* Center the text horizontally */
    height: 305px;
    font-size: medium;       /* Increase font size */
       /* Make the text bold (optional) */
    color: white;             /* Set the text color (optional) */
  }

  .widget-title {
    font-weight: bold;
    text-align: left;
    font-size: x-large;
    margin-bottom: 20px;
    color: white; /* A brighter color for the title */
  }
  
  .highlights-container {
    
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .highlight-section {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width */
    margin: 10px;
    padding: 15px;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .highlight-text {
    margin: 0;
    font-size: 1em; /* Slightly larger for better readability */
    line-height: 1.5;
  }
  
  .loading-message {
    color: white;
    text-align: center;
    padding: 20px;
  }
  