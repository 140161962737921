.playback-control-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 20px;
  }
  
  .playback-button-group {
    display: flex;
    gap: 15px;
  }
  
  .control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 24px;
    background-color:  var(--bgColorNorma)  !important; /* 确保背景颜色被应用 */
    color: #ffffff !important; /* 确保文本颜色被应用 */
    border: 2px solid #4a90e2 !important; /* 确保边框颜色被应用 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .control-button:hover {
    background-color: #4a90e2 !important; /* 确保 hover 状态下背景颜色被应用 */
    color: #ffffff !important; /* 确保 hover 状态下文本颜色被应用 */
    transform: scale(1.05);
  }
  
  .control-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.5);
  }
  
  .control-button:active {
    transform: scale(0.95);
  }
  
  .current-time-playback {
    width: auto;
    min-width: 200px;
    border-radius: 30px;
    font-size: 16px;
    padding: 0 15px;
    background-color: var(--bgColorNorma) !important; /* 确保与按钮一致的背景颜色 */
    color: #ffffff !important; /* 确保与按钮一致的文本颜色 */
    border: 2px solid #4a90e2 !important; /* 确保与按钮一致的边框 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  