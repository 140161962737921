@import './ThemeVariable.css';

.basic-column {
  height: 100%;

  max-width :100vw;
  display: flex;
  flex-direction: column;
  min-width: inherit;
}


@media (max-width: 1281px) { 
  .basic-column {
    max-height: 100vh;
    height: 100%;
    width: inherit;
    max-width :inherit;
    display: flex;
    flex-direction: column;
    min-width: inherit;
  }
  .navbar.main-container >.basic-column {
 
    max-height: 1rem;
  }
}