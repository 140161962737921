.vehicle-page-edit.container {
    min-width: 1000px;
    height: 100%;
    padding: var(--cardPadding);
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    background-color: var(--bgColorNormal);
    margin-left: 0%;
}

.vehicle-page-edit.device-Id {
  font-size: x-large;
  color: white;
}

.vehicle-page-edit.driver-Id-content {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin)
}

.vehicle-page-edit.device-Id-content {
  font-size: large;
  color: white;
  margin-bottom: var(--controlVMargin)
}
 
.vehicle-page-edit.title{
    font-size: large;
    color:white;
    margin-bottom: var(--controlVMargin)
}

.vehicle-page-edit.input{
    font-size: large;
    background-color: white;
    border-radius: 7px;
    margin-bottom: var(--controlVMargin)
}

.vehicle-page-edit.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: gray;
    border-style: dashed;
    background-color: transparent;
    color: white;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-radius: 7px;
}
.vehicle-page-edit.upload-area{
    position: relative;
    width: 600px;
    height: 100px;
    display: flex;
    flex-direction: column;
    color: white;
}

.vehicle-page-edit.image
{
    max-width: 450px;
    max-height: auto;
    border-radius: 8px;
}