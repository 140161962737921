.fleet-page-headline.container {
  min-height: 100vh;
  min-width: 98vw;
  height: var(--mainLayoutHeight);
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}
.fleet-page-headline.column.left{
  width: 50.14vw; 

  min-width: auto;
  max-width: 50.14%;

  margin-right: var(--cardGap);
  display: flex;
  height: 90vh;

  flex-direction: column;
}
.fleet-page-headline.column.right{
  width: 90vw;
  height: 90vh;
  min-width:auto;
  max-width: 41.86%;

  margin-right: var(--cardGap);
  display: flex;
  flex-direction: column;
}

.fleet-page-headline.text {

  position: relative;
  top: 20px;
  min-width: auto;
  width: auto;
  height: 70px;
  font-size: large;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}



@media (max-width: 1281px) { 
  .fleet-page-headline.container {
    min-width: 100vw;
    flex-direction: column; /* Stack items vertically */
  }

  .fleet-page-headline.column.left{
    width: 90vw; 
    height: 90vh;
    min-width: auto;
    max-width: 50vw;
    height: var(--mainLayoutHeight);
    margin-right: var(--cardGap);
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem; /* Add spacing below the left column */
  }
  .fleet-page-headline.column.right{
    position: reative;
    width: 40.86vw;
    height: 100%;
    margin-top: 1rem;
 
    min-width:auto;
    max-width: 41.86%;
    height: var(--mainLayoutHeight);
    margin-right: var(--cardGap);
    display: flex;
    flex-direction: column;
  }
  .fleet-page-headline.column.left, 
  .fleet-page-headline.column.right {
    width: 90vw; /* Or whatever width you need for smaller screens */
    max-width: 90vw; 
    margin-right: 0; /* Reset any horizontal margins */
    position: static; /* Return to normal flow on smaller screens */
  } 
}