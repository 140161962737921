/*
.legend {
    bottom: 30px;
    left: 20px;
    line-height: 18px;
    color: #444444;
    background: rgba(255, 255, 255, 0.8);
    padding: 6px 8px;
  }
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.9;
    font-family: var(--fontFamily);
  }
*/

.event-page-map.container {
  position: absolute;
  top: 20px;
  left: 380px;
  height: 310px;
  width: 460px;
  border-radius: 7px;
}

.event-page-map-custom-marker-cluster {
  border-radius: 50%;
  color: black;
  font-size: x-large;
  font-family: var(--fontFamily);
  text-align: center;
  width: 50px;
  height: 50px;
  background-image: radial-gradient(
    rgba(250, 95, 22, 0.7),
    rgba(231, 173, 13, 0.7),
    rgba(220, 235, 13, 0.7)
  );
}

.event-page-map-popup .leaflet-popup-content-wrapper {
  height: 118px;
  width: 280px;
  padding-left: 60px;
  padding-top: 5px;
  font-family: var(--fontFamily);
  font-size: large;
  text-align: center;
  border-radius: 7px;
  color: var(--textColor);
  background: var(--bgColorNormal);
}

.event-page-map-popup .leaflet-popup-content-wrapper:hover {
  background: var(--bgColorLight);
  cursor: pointer;
}

.event-page-map-popup.map {
  position: absolute;
  border-radius: 7px;
  left: 30px;
  top: 415px;
  height: 345px;
  width: 1250px;
}


