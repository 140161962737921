@import './ThemeVariable.css';

.spacer {
  width: 30vw;
}

@media(max-width:1280px){
  .spacer {
    width: 10vw;
  }  

}