.description-box {
  position: relative;
  width: 600px;
  height: 500px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding for the text */
  top: 0rem;
  left: 5rem;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width/height */
  overflow-x: auto;
}
@media(max-width:1281px)
{
  .description-box {
    margin-top: 50px;
    position: relative;
    width: 600px;
    height: 450px;
    border: 1px solid #ccc; /* Border color */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding for the text */
    top: 0rem;
    left: 5rem;
    box-sizing: border-box; /* Ensures padding doesn't affect overall width/height */
    overflow-x: auto;
  }
}

.route-row.container{
  height: 450px;
}
.fleet-addtask.container {
  min-width: 70rem;
  width: 70vw;
  height: 820px;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: var(--cardPadding);
  display: flex;
  flex-direction: column;
  margin-left: 0%;
}

.fleet-addtask.data-column {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.fleet-addtask.map {
  margin-top: 20px;

  width: 774px;
  min-width: 20rem;
  height: 970px;
  border-radius: 7px;
  z-index: 0;
}

.fleet-addtask.route-date-picker {
  margin-left: 16px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fleet-addtask.route-row {
  display: flex;
  width: 750px;
  padding-bottom: 10px;
}

.fleet-addtask.index-label {
  color: white;
  font-size: x-large;
  display: grid;
  place-items: center;
  margin-left: 30px;
}

.fleet-addtask.title-row {
  display: flex;
  justify-content: space-between;
}

.fleet-addtask.title {
  height: auto;
  width: 300px;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-addtask.info {
  position: relative;
  top: 40px;
  left: 30px;
  height: auto;
  width: auto;
  font-size: large;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-addtask.name {
  position: relative;
  top: 0px;
  left: 60px;
  margin-top: 25px;
  height: auto;
  width: 210px;
  margin-right: 10px;
  font-size: large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.fleet-addtask.full-name-field {
  position: relative;
  left: 60px;
  height: auto;
  width: 560px;
  font-size: x-large;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: white;
  border-radius: 7px;
}

.fleet-addtask.name-field {
  position: relative;
  top: 0px;
  left: 16px;
  margin-right: 10px;
  height: 40px;
  width: 210px;
  font-size: x-large;
  font-weight: bold;
  font-family: var(--fontFamily);
  background-color: white;
  border-radius: 7px;
}

.fleet-addtask.dropdown {
  position: relative;
  left: 65px;
  margin-top: 5px;
  margin-left: -20px;
  width: 240px;
  border-radius: 7px;
}

.fleet-addtask.minus-image {
  position: relative;
  width: 40px;
  height: 40px;
  top: 0px;
  border: none; /* Remove borders */
  background: transparent; 
}

.fleet-addtask.minus-image img {
  width: 100%;
  height: auto;
  display: block; /* Remove line breaks around image */
}

.fleet-addtask.minus-image:disabled {
  filter: grayscale(100%);
  opacity: 0.4;
  pointer-events: none;
}

.fleet-addtask.minus-image:hover {
  opacity: 0.8;
}

.fleet-addtask.add-row {
  display: flex;
  width: 750px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fleet-addtask.add-image {
  position: relative;
  width: 50px;
  height: 50px;
  border: none; /* Remove borders */
  background: transparent;
  
}

.fleet-addtask.add-image img {
  width: 100%;
  height: auto;
  display: block; /* Remove line breaks around image */
}

.fleet-addtask.empty-text {
  position: relative;
  top: 5px;
  width: auto;
  height: auto;
  font-size: large;
  text-align: left;
  color: white;
  font-family: var(--fontFamily);
}

.fleet-addtask.toast-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:1281px){
  .fleet-addtask.name-field {
    position: relative;
    top: 0px;
    left: 16px;
    margin-right: 10px;
    height: 40px;
    width: 150px;
    font-size: x-large;
    font-weight: bold;
    font-family: var(--fontFamily);
    background-color: white;
    border-radius: 7px;
  }
  .fleet-addtask.map {
    margin-top: 20px;

    width: 10rem;
    min-width: 20rem;
   
    border-radius: 7px;
    z-index: 0;
  }
  .fleet-addtask.full-name-field {
    position: relative;
    left: 60px;
    height: auto;
    width: 300px;
    font-size: x-large;
    font-weight: bold;
    font-family: var(--fontFamily);
    background-color: white;
    border-radius: 7px;
  }

  .fleet-addtask.name {
    position: relative;
    top: 0px;
    left: 60px;
    margin-top: 25px;
    height: auto;
    width: 110px;
    margin-right: 55px;
    font-size: large;
    font-weight: bold;
    color: white;
    font-family: var(--fontFamily);
    background-color: transparent;
  }
  .fleet-addtask.dropdown {
    position: relative;
    left: 65px;
    margin-top: 5px;
    margin-left: -20px;
    width: 180px;
    border-radius: 7px;
  }
  .fleet-addtask.container {
    min-width: 60rem;
    width: 70vw;
    height: 820px;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: var(--cardPadding);
    display: flex;
    flex-direction: column;
    margin-left: 0%;
  }
}