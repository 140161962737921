.settings-geofencing.container {
  max-width: 100%;

  
  height: var(--mainLayoutHeight);
  padding: var(--cardPadding);
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  background-color: var(--bgColorNormal);
  z-index: 20;
 
}

.settings-geofencing.title-row {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.settings-geofencing.type-group {
  margin-top: 5px;
  margin-right: var(--cardGap);
  width: 400px;
}

.settings-geofencing.vehicle {
    margin-top: 5px;
    margin-right: auto;
  min-width: 5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings-geofencing.button-group-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.settings-geofencing.map-container {
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 100%;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  z-index: 0;
}

.settings-geofencing.description {
  color: white;
  font: large;
  margin-bottom: var(--cardGap);
  font-family: var(--fontFamily);
  background-color: var(--bgColorNormal);
}

.settings-geofencing.alert {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.settings-geofencing.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
