

.driver-page-map.container {
  position: relative;
  z-index: 0;
  max-width: 100%;
}

.driver-page-map.map-container {
  height: 44.2rem;
 
  border-radius: 7px;
  z-index: 0;
  width: 40rem;
  margin-top: 1.5rem;
}

@media(max-width:1920px){
  .driver-page-map.map-container {

    border-radius: 7px;
    z-index: 0;
    width: 35vw;
    margin-top: 1.5rem;
  }   
}
@media(max-width:1690px){
  .driver-page-map.map-container {

   
    border-radius: 7px;
    z-index: 0;
    width: 30vw;
    margin-top: 1.5rem;
  }
}
@media(max-width:1600px){
  .driver-page-map.map-container {
    height: 44rem;
   
    border-radius: 7px;
    z-index: 0;
    width: 25vw;
    margin-top: 1.5rem;
  }
}
.driver-page-map-custom-marker-cluster {
  border-radius: 50%;
  color: black;
  font-size: medium;
  font-family: var(--fontFamily);
  text-align: center;
  width: 50px;
  height: 50px;
  background-image: radial-gradient(
    rgba(250, 95, 22, 0.7),
    rgba(231, 173, 13, 0.7),
    rgba(220, 235, 13, 0.7)
  );
}

.driver-page-map-popup .leaflet-popup-content-wrapper {
  height: 90px;
  width: 260px;
  padding-left: 60px;
  padding-top: 5px;
  font-family: var(--fontFamily);
  font-size: large;
  text-align: center;
  border-radius: 7px;
  color: var(--textColor);
  background: var(--bgColorNormal);
}

.driver-page-map-popup .leaflet-popup-content-wrapper:hover {
  background: var(--bgColorLight);
  cursor: pointer;
}

.driver-page-map-popup.lost-connection-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.7); /* Semi-transparent red background */
  color: white;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  z-index: 1000; /* Ensure it's above the map */
}
@media (max-width: 1470px) {
  .driver-page-map.map-container {
    height: 44rem;
    
    border-radius: 7px;
    z-index: 0;
    width: 40rem;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (max-width: 1281px) {
  .driver-page-map.map-container {
    height: 28rem;
    scrollbar-width: auto;
    border-radius: 7px;
    z-index: 0;
    width: 40rem;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

}
