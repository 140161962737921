.scrollable-container {
    min-width: none;
    max-height: 100vh;
    min-height: 50vh;
  
    overflow-y: hidden; /* 允許垂直滾動 */
    
}
@media(max-width:1281px) {
    .scrollable-container-register {
        min-width: 923px;
        width: 70vw;
        overflow-x: auto;
        overflow-y: hidden; /* 允許垂直滾動 */
        
    }
}
.scrollable-container-register {
  
    width: 65vw;
    overflow-x: auto;
    overflow-y: hidden; /* 允許垂直滾動 */
    
}
.scrollable-container.event {
    display: flex;
    min-width: none;
    max-height: 100vh;
    min-height: 50vh;
    overflow-y: hidden; /* 允許垂直滾動 */
    
}
@media(max-width:2125px){

    .scrollable-container.event {
        display:contents;
        overflow: hidden;
        height: 20rem;
    }
}
.scrollable-container.register{
    min-width: none;

    max-height: 100vh;
    min-height: 50vh;
    scrollbar-width: none;
    overflow-y: auto; /* 允許垂直滾動 */
    overflow-x: hidden;
    
}
.vehicle-page-info.data-container > .scrollable-container {
    height: 51.4rem;

    width: 720px;
    overflow-x: hidden; /* 禁止橫向滾動 */
    overflow-y: auto; /* 允許垂直滾動 */
    direction: ltr;
    scroll-padding: 50px 50px 50px 50px;
}
::-webkit-scrollbar {
    width: 12px; /* 滚动条的宽度 */
    height: 12px; /* 滚动条的高度（适用于水平滚动条） */
   
  }
  
  /* 定制滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* 滑块的背景颜色 */
    border-radius: 10px; /* 滑块的圆角 */
    border: 3px solid transparent; /* 滑块的边框 */
    background-clip: content-box; /* 背景裁剪，使边框透明 */
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* 轨道的背景颜色 */
    border-radius: 10px; /* 轨道的圆角 */
  }
  
  .vehicle-page-info.data-container > .scrollable-container::-webkit-scrollbar {
    
    width: 12px;
    position: relative;
   
    margin-top: 10px;
  }
  .vehicle-page-info.data-container > .scrollable-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    
  }

.event-page-info.info-column .scrollable-container
    {
        height: calc(66vh + 150px);
        width: 70vw;
      
        min-height: 40vh;
        overflow-y: auto; /* 允許垂直滾動 */

        scrollbar-width: none;
    }
.scrollable-container .event-sidebar{
    min-width: none;

    max-height: 90vh;
    min-height: 50vh;
    overflow-y: hidden; /* 允許垂直滾動 */
}
.scrollable-container.vehicle-page-info{
    height: 48rem;
    scrollbar-width: none;

    max-height: 48rem;
    min-height: 48rem;
    overflow-y: auto; /* 允許垂直滾動 */
    overflow-x: hidden;
    
}

.scrollable-container.driver-page-info{
 
    scrollbar-width: none;
    max-height: 790px;
    height: 48.3rem;
    overflow-y: auto; /* 允許垂直滾動 */
    min-width: 690px;
    
 
}


::-webkit-scrollbar {
    width: 12px; /* 滚动条的宽度 */
    height: 12px; /* 滚动条的高度（适用于水平滚动条） */

  }
  
  /* 定制滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* 滑块的背景颜色 */
    border-radius: 10px; /* 滑块的圆角 */
    border: 3px solid transparent; /* 滑块的边框 */
    background-clip: content-box; /* 背景裁剪，使边框透明 */
  }
.scrollable-container.driver-page-info::-webkit-scrollbar{
    width: 12px;
    position: absolute;
    top:10px;
}

.scrollable-container.driver-page-info::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
  
.scrollable-container.fleet{
    min-height: 650px;
    height :90vh;
    overflow-x: hidden;

}
.scrollable-container.fleetmap{
    min-height: 650px;
    height :100vh;
    overflow-x: hidden;
    

}
.scrollable-container-coach{
    min-height: 650px;
    height :90vh;
    overflow-x: hidden;
    width: 90vw;

}

.scrollable-container.fleet-unregister-device-page{
    width: auto;
    max-width: 90vw;
    min-width: 70rem;
}
.scrollable-container.register-driver{
    overflow-x: hidden;
    overflow-y: hidden; /* 允許垂直滾動 */
}
.scrollable-container.geofence {
    position: sticky;
    max-width: 90vw;
    width: 90vw;
    max-height: 100vh;
    min-height: 50vh;
    z-index: 20;
    overflow-x: auto; /* 允許垂直滾動 */
    min-width: 1143px;
}
@media (max-width: 1281px) { 
    .scrollable-container.fleet{
        width: 100%;
        height:  100%;
        display: flex;
        padding: var(--cardPadding);
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .scrollable-container {
        max-height: 100vh; /* 限制最大高度為 70vh */
        min-height: 90vh;
        overflow-y: auto; /* 允許垂直滾動 */
    }
    .driver-page-info.data-column > .scrollable-container {
   
        min-width: 697px;
        
        overflow-y: auto; /* 允許垂直滾動 */
        flex-direction: column;
      

    }

    .vehicle-page-info.data-container > .scrollable-container {
        min-width: 700px;
        max-width: 900px;
        width: fit-content; /* 自動調整寬度以適應內容 */
        overflow-y: auto; /* 允許垂直滾動 */
    }
    .scrollable-container .register{
        min-width: none;
        max-height: 90vh;
        min-height: 50vh;
        overflow-y: hidden; /* 允許垂直滾動 */
        
    }
    .scrollable-container.event-sidebar{
        min-width: none;
        z-index: 9999;
        max-height: 90vh;
        min-height: 60vh;
        overflow-y: hidden; /* 允許垂直滾動 */
    }
    .scrollable-container.vehicle-page-info{
        min-width: none;
        max-height: 90vh;
        min-height: 50vh;
        overflow-x: hidden; /* 允許垂直滾動 */
        overflow-y: scroll; /* 允許垂直滾動 */
   
    }
    .scrollable-container.event {
        min-width: 70vw;
        max-height: 100vh;
        min-height: 50vh;
        overflow-y: hidden; /* 允許垂直滾動 */
        
    }
    .scrollable-container.register-driver{

        overflow-y: hidden; /* 允許垂直滾動 */
        overflow-x: hidden;
    }

    .scrollable-container-coach{
        width: 80vw;
        margin-top: 3rem;
    
        display: flex;
        flex-direction: row-reverse;
        height: -webkit-fill-available ;
    }

}
