.driver-page-info.container {
  height: -webkit-fill-available;
  min-width: 100%;
  background-color: transparent;
  display: table;
  flex-direction: column;
}

.driver-page-info.overview-container {
  display: grid;
  grid-template-columns: 1fr 9fr 2fr 14fr; /* The "fr" unit distributes space based on the fraction of the available space */
  max-width: 1296px;
  height: auto;

  background-color: transparent;
}

.driver-page-info.data-container {
  display: flex;
  width: 71vw;
 
}

.driver-page-info.image-column {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  background-color: transparent;
}

.driver-page-info.coach-column {
  position: relative;
  top: 25px;
  background-color: transparent;
}

.driver-page-info.datepicker-row {
  display: flex;
  align-items: center;
}

.driver-page-info.datepicker-column {
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.driver-page-info.circle {
  position: absolute;
  top: -25px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: large;
  font-weight: bold;
  text-align: center;
}

.driver-page-info.name {
  box-sizing: border-box;
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.driver-page-info.edit {
  margin-left: var(--controlHMargin);
  border: none;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.driver-page-info.left-arrow {
  margin-left: 0px;
  margin-right: 17px;
  border: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.driver-page-info.right-arrow {
  margin-left: 0px;
  border: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.driver-page-info.driver-id {
  box-sizing: border-box;
  font-size: medium;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.driver-page-info.manager {
  box-sizing: border-box;
  font-size: medium;
  color: white;
  text-align: left;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.driver-page-info.device-info-button {
  top: 50px;
  left: 740px;
  width: 100px;
  position: absolute;
}
.driver-page-info.device-info-button:hover {
  background-color: var(--colorGreenDark);
}

.driver-page-info.datepicker-button {
  position: absolute;
  left: 0px;
  height: 45px;
  width: 155px;
  border-radius: 7px;
  font-size: large;
  font-family: var(--fontFamily);
  background-color: var(--colorGreen);
  border: none;
}

.driver-page-info.data-column {
  display: flex;
  flex-direction: column;
  margin-right: var(--cardGap);
}
@media(max-width:1470px){

  .driver-page-info.data-container {
    display: flex;
    width: 100%;
    min-width: 907px;
    height: 49.8rem;
    overflow-x: auto;
    scrollbar-width: none;
  }
  .driver-page-info.data-column {
    display: flex;
    flex-direction: column;
    margin-right: var(--cardGap);
  
    
    width: 58vw;
    min-width: 682px;
   
  }
}
@media(max-width:1281px){


  .driver-page-info.data-container {
    display: flex;
    width: 100%;
    min-width: 907px;
    height: 49.8rem;
    overflow-x: auto;
    scrollbar-width: none;
  }
}

