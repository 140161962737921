    .settings-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #343a40;
    padding: 2rem;
    border-radius: 8px;
  }
  
  .version {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .text-white {
    color: #ffffff !important;
  }
  
  .dropdown-menu .dropdown-item {
    color: #ffffff !important;
    background-color: #343a40;
  }
  
  .dropdown-menu .dropdown-item:hover {
    background-color: #495057;
  }
  
  button {
    font-size: 1rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  