.css-z8fhq1-MuiDataGrid-columnHeaders{
  background-color:darkgreen;
  color:white;
}

.coach-page-card.detail-container {
  top: 30px;
  border-radius: 7px;
}

.coach-page-card.info-list {
  position: relative;
  border-right: transparent;
  border-left: transparent;
  border-top: solid;
  border-top-width: 3px;
  border-top-color: var(--bgColorLight);
  background-color: var(--bgColorNormal);
  background-color: transparent;
}

.coach-page-card.info-content-id {
  position: relative;
  color: white;
  font-size: medium;
  text-align: right;
  font-family: var(--fontFamily);
  display: inline;
  background-color: transparent;
}
.coach-page-card.info-content-date {
  position: relative;
  color: white;
  font-size: medium;
  text-align: right;
  font-family: var(--fontFamily);
  display: inline;
  background-color: transparent;
}

.coach-page-card.info-content-severity {
  position: relative;
  color: white;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-subject {
  position: relative;
  color: white;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-user {
  position: relative;
  font-weight: bold;
  color: lightskyblue;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-driver {
  position: relative;
  color: white;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-discovery {
  position: relative;
  float: left;
  color: white;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-interview {
  position: relative;
  float: left;
  color: white;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-action {
  position: relative;
  float: left;
  color: white;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-monitor {
  position: relative;
  float: left;
  color: white;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-new {
  position: relative;
  float: left;
  color: white;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-closed {
  position: relative;
  float: left;
  color: gray;
  text-align: left;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.info-content-status-due {
  position: relative;
  color: #ffffff;
  font-size: medium;
  font-family: var(--fontFamily);
}

.coach-page-card.info-content-status-due-expired {
  position: relative;
  color: rgb(212, 12, 12);
  font-size: medium;
  font-family: var(--fontFamily);
}

.coach-page-card.info-name:hover {
  color: red;
  font-weight: bold;
}

.coach-page-card.info-comment {
  position: relative;
  font-weight: 500;
  color: yellowgreen;
  font-size: medium;
  font-family: var(--fontFamily);
  display: inline;
}

.coach-page-card.comment-button {
  position: relative;
}

.coach-page-card.all-status-button {
  position: relative;
}

.coach-page-card.status-dropdown {
  position: relative;
}

.coach-page-card.active-coach-title {
  font-weight: bold;
  float: right;
  color: lightyellow;
  font-size: medium;
  font-family: var(--fontFamily);
  margin-right: 10px;
}

.coach-page-card.overdue-coach-title {
  font-weight: bold;
  float: right;
  color: lightyellow;
  font-size: medium;
  font-family: var(--fontFamily);
}

.coach-page-card.driver-name {
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  margin-right: 50px;
}

.coach-page-card.driver-manager {
  font-size: x-large;
  color: white;
  font-family: var(--fontFamily);
}

.coach-page-card.description {
  position: relative;
  color: white;
}

.coach-page-card.description-id {
  position: relative;
  color: white;
  text-transform: none;
}

.coach-page-card.description-subject {
  position: relative;
  color: white;
  text-transform: none;
}

.coach-page-card.description-stage {
  position: relative;
  color: white;
  text-transform: none;
}

.coach-page-card.description-created-date {
  position: relative;
  color: white;
  text-transform: none;
}
.coach-page-card.description-due-day {
  position: relative;
  color: white;
  text-transform: none;
}

.coach-page-card.description-author {
  position: relative;
  color: white;
  text-transform: none;
}


.coach-page-card.count-row {
  display: flex;
  margin-bottom: 10px;
}

.coach-page-card.button-row {
  display: flex;
  margin-bottom: 10px;
}

.coach-page-card.no-date-text {
  position: relative;
  top: 20px;
  width: auto;
  height: 70px;
  font-size: large;
  text-align: center;
  color: white;
  font-family: var(--fontFamily);
}

.coach-page-card.side {
  display: flex;
  flex-direction: column;
}

.coach-page-card.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coach-page-card.button-group {
  display: flex;
  gap: 10px;
}
