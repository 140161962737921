.statistic-rank-container.container {
  color: white;
  width: 100%;
  border-radius: var(--borderRadius);
  background-color: var(--bgColorNormal);
  padding: var(--cardPadding);
  margin-top: var(--cardGap);
  flex-grow: 1;
}
.table-container > .card-container.top-card-margin.grow-width > .statistic-rank-container.table-column {
  overflow-x: auto;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  width: 70rem;

}
.statistic-rank-container.table-column {
  overflow-x: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  min-height: 20rem;
}
.statistic-rank-container.table-column.vehicleutilization{
  overflow-x: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: auto;
}
.title-button-container  {
  display: flex;
}
.statistic-rank-container.table-container {
  border-radius: var(--borderRadius);
  width: max-content;
  overflow-y: scroll;
  background-color: var(--bgColorNormal);
  padding-top: auto;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}


.card-container.top-card-margin.grow-width {
  display: flex;
  position: sticky;
  height: -webkit-fill-available;
  border-radius: 10px;
  width: fit-content;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1281px) { 
  .card-container.top-card-margin.grow-width {
    min-width: 70vw;
    width: 70vw;
    align-self: center;
  }
}