.css-z8fhq1-MuiDataGrid-columnHeaders{
  background-color:darkgreen;
  color:white;
}

.statistic-page-table-card.outer-container {
  min-width: 20vh;
  min-height: 40rem;
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  padding: var(--cardPadding);
  background-color: var(--bgColorNormal);
  height:100%;
  

  overflow-x : auto;
  /* Hide scrollbar for Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

}
.main.flex-item-half.table-container > .statistic-page-table-card.outer-container.headline {
  min-width: 37vw;
  max-height: 20vh;
}
.statistic-page-table-card.title {
  top: var(--titleTopMargin);
  left: var(--titleLeftMargin);
  width: auto;
  height: auto;
  font-size: x-large;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily);
  background-color: transparent;
}

.statistic-page-table-card.table-container {
  border-radius: var(--borderRadius);
  width: 100%;
  scrollbar-width: none;

  background-color: var(--bgColorNormal);
  overflow-x: auto;
  overflow-y: auto;
}


@media (max-width: 1281px) { 
  .vehicle-page-timeline-card.container {
    min-width: 700px;
    min-height: 220px;
    margin-top: 0px;
    margin-left: 0px;
    max-height: 230px;
    margin-left: 1rem;
    width: 55vw;
    border-radius: var(--borderRadius);
    background-color: var(--bgColorNormal);
    padding: var(--cardPadding);
  }
  
  .main.flex-item-half.table-container > .statistic-page-table-card.outer-container.headline {
    min-height: 25vh;
    max-height: 25vh;
  }
  .fleet-page-headline.column.left  >  .statistic-page-table-card.outer-container.headline {
    min-width: 30rem;
    min-height: 491px;
    width: 90vw;
    height: auto;
    max-height:65vh;
    padding: var(--cardPadding);
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    padding: var(--cardPadding);
    background-color: var(--bgColorNormal);
    overflow-y: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .statistic-page-table-card.outer-container {
    min-width: 20vh;
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    padding: var(--cardPadding);
    background-color: var(--bgColorNormal);
    height: auto;
    min-height: 57.5rem;
    max-height: 85rem;
    overflow-x : auto;
    /* Hide scrollbar for Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
}
